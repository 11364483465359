import ContentLoader from "react-content-loader";

import { colors } from "@/styles";

const SkeletonMobileItem = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={60}
    backgroundColor={colors.skeletonBackgroundColor}
    foregroundColor={colors.skeletonForegroundColor}
  >
    <rect x="2%" y="0" width="30" height="30" rx={5} ry={5} />

    <rect x="20%" y="6" width="20%" rx={5} ry={5} height="20" />
    <rect x="50%" y="6" width="20%" rx={5} ry={5} height="20" />
    <rect x="78%" y="6" width="20%" rx={5} ry={5} height="20" />
  </ContentLoader>
);

export default SkeletonMobileItem;
