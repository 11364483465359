import { useRef } from "react";

type CancellablePromise<T> = {
  promise: Promise<T>;
  cancel: () => void;
};

function cancellablePromise<T>(promise: Promise<T>): CancellablePromise<T> {
  let isCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true;
    },
  };
}

export const noop = (): void => {};

export const delay = (n: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, n));

export const useCancellablePromises = () => {
  const pendingPromises = useRef<CancellablePromise<any>[]>([]);

  const appendPendingPromise = (promise: CancellablePromise<any>) =>
    (pendingPromises.current = [...pendingPromises.current, promise]);

  const removePendingPromise = (promise: CancellablePromise<any>) =>
    (pendingPromises.current = pendingPromises.current.filter(
      (p) => p !== promise,
    ));

  const clearPendingPromises = () =>
    pendingPromises.current.map((p) => p.cancel());

  const api = {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };

  return api;
};

type ActionFunction = () => void;

function userCancelableAction<T extends HTMLElement>(
  delayMs: number,
  action: ActionFunction,
  cancelAction?: ActionFunction,
  preventDefault: boolean = true,
): [(e: React.MouseEvent<T>) => void, (e: React.MouseEvent<T>) => void] {
  const api = useCancellablePromises();

  const handleAction = (e: React.MouseEvent<T>) => {
    if (preventDefault) {
      e.stopPropagation();
      e.preventDefault();
    }
    api.clearPendingPromises();
    const waitForClick = cancellablePromise(delay(delayMs));
    api.appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        action();
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleCancelAction = (e: React.MouseEvent<T>) => {
    api.clearPendingPromises();
    if (preventDefault) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (cancelAction) cancelAction();
  };

  return [handleAction, handleCancelAction];
}

export default userCancelableAction;
