import ContentLoader from "react-content-loader";

import { colors } from "@/styles";

const SkeletonItem = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={60}
    backgroundColor={colors.skeletonBackgroundColor}
    foregroundColor={colors.skeletonForegroundColor}
  >
    <rect x="1%" y="0" width="30" height="30" rx={5} ry={5} />

    <rect x="5%" y="6" width="8%" rx={5} ry={5} height="20" />
    <rect x="33%" y="6" width="4%" rx={5} ry={5} height="20" />
    <rect x="50%" y="6" width="7%" rx={5} ry={5} height="20" />
  </ContentLoader>
);

export default SkeletonItem;
