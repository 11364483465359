import { fadeInUp } from "react-animations";
import styled, { keyframes } from "styled-components";

import { ParcelCol, ParcelRow } from "@/styles/parcels";

interface WrapperProps {
  disabled: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  animation: 0.6s ${keyframes`${fadeInUp}`};

  &:not(:first-child) > div {
    border-top: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const TableCol = styled(ParcelCol)`
  text-align: left;
  line-height: 20px;
  user-select: none;

  .tooltip-content-checkbox {
    min-width: 18px;
  }

  &:nth-child(4) > div {
    margin-left: 15px;
  }

  ${(props) => props.theme.lg`
    white-space: normal;
  `}

  ${(props) => props.theme.xs`
    &:nth-child(4) > div {
      margin-left: 10px;
    }
  `}
  
  ${(props) => props.theme.xs`
    margin-bottom: 5px;
  `}
`;

export const TableRow = styled(ParcelRow)`
  ${({ theme }) => theme.range("padding", "0px", "6px")};

  ${(props) => props.theme.xs`
    padding: 5px 0 0;
  `};

  &:first-child {
    border-top: none;
  }

  & > ${TableCol} {
    justify-content: center;

    &:nth-child(2) {
      justify-content: start;
    }

    &:last-child {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }
`;

export const StickyTableRow = styled(TableRow)`
  position: sticky;
  top: 200px;
  z-index: 1;
`;

export const TransactionsTableHeading = styled.div`
  font-weight: bold;

  & ${TableCol} {
    justify-content: center !important;
  }
`;

export const ScrollableTableBody = styled.div`
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;

  ${(props) => props.theme.xs`
    max-height: 100%;
    overflow-y: hidden;
  `};
`;

export const Card = styled.div`
  border-radius: 10px;
  background-color: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.whiteSmoke};
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const CardFooter = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const CardFooterItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const CardItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  &:first-child {
    flex-shrink: 0;
    width: 50px;
    height: auto;
  }

  &:last-child {
    flex-grow: 1;
    word-wrap: break-word;
  }
`;
