import React, { FC, MouseEvent, ReactNode } from "react";

import { CustomButton, Spinner } from "./Button.style";

import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  PreLoaderColor,
} from "@/enums";

export interface ButtonProps {
  children: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  type?: ButtonType;
  size?: ButtonSize;
  variant?: ButtonVariant;
  color?: ButtonColor;
  title?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  tabIndex?: number;
  hidden?: boolean;
  className?: string;
}

const Button: FC<ButtonProps> = ({
  children,
  isLoading = false,
  color = ButtonColor.Black50,
  variant = ButtonVariant.Basic,
  size = ButtonSize.Small,
  tabIndex = 0,
  disabled = false,
  type = ButtonType.Button,
  className = "",
  ...otherProps
}) => {
  const spinnerColor =
    color === ButtonColor.Black50 ? PreLoaderColor.Grey : PreLoaderColor.White;

  return (
    <CustomButton
      size={size}
      color={color}
      $variant={variant}
      tabIndex={tabIndex}
      disabled={disabled}
      type={type}
      className={className}
      {...otherProps}
    >
      {isLoading && <Spinner color={spinnerColor} isActive={isLoading} />}
      {children}
    </CustomButton>
  );
};

export default React.memo(Button);
