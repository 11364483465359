import React, { useEffect, useState } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import ServicesAPI from "@/api/services_api";

import {
  toastResponseError,
  toastResponseSuccess,
} from "@/utils/responseMessageHelper";

import { useToastStore } from "@/store";

import {
  BugButton,
  BugButtonDivider,
  BugButtonMessage,
  BugButtonWrapper,
} from "./ReportBugButton.styles";

interface ToastReportBugButton {
  message: string;
  response: AxiosError;
  toastId?: string;
}

const ToastReportBugButton = ({
  message,
  response,
  toastId,
}: ToastReportBugButton) => {
  const { t } = useTranslation("common");
  const [isShowBtn, setIsShowBtn] = useState(true);

  const { updateIsToastWithAxiosError } = useToastStore();

  useEffect(() => {
    updateIsToastWithAxiosError(true);

    return () => updateIsToastWithAxiosError(false);
  }, []);

  const handleCallUserSnapRequest = async () => {
    setIsShowBtn(false);

    const orderedInputs = [
      {
        field_type: "text",
        label: "Title",
        value: `API Error ${response?.config?.method?.toUpperCase()}, ${response?.config?.url}`,
      },
      {
        field_type: "text",
        label: "Request Method",
        value: response?.config?.method?.toUpperCase(),
      },
      {
        field_type: "text",
        label: "Response Code",
        value: response.code,
      },
      {
        field_type: "textarea",
        label: "Request Body",
        value: response?.config?.data,
      },
      {
        field_type: "text",
        label: "Request Status",
        value: response.request.status,
      },
      {
        field_type: "text",
        label: "Request Status Text",
        value: response.request.statusText,
      },
      {
        field_type: "text",
        label: "Response Msg",
        value: response.message,
      },
      {
        field_type: "text",
        label: "Full API url",
        value: response.request.responseURL,
      },
      {
        field_type: "text",
        label: "User UID",
        value: response?.config?.headers.uid,
      },
      {
        field_type: "text",
        label: "Request Stack",
        value: response.stack,
      },
    ];

    await ServicesAPI.postReportBugUserSnap(orderedInputs).then((res) => {
      setTimeout(() => {
        toast.dismiss(toastId);
        updateIsToastWithAxiosError(false);
        res.data.status
          ? toastResponseSuccess(t("toast.thankYou"))
          : toastResponseError(t("toast.somethingWentWrong"));
      }, 3000);
    });
  };

  return (
    <BugButtonWrapper>
      <BugButtonMessage>{message}</BugButtonMessage>
      <BugButtonDivider isShowBtn={isShowBtn} />
      <BugButton onClick={handleCallUserSnapRequest} isShowBtn={isShowBtn}>
        {t("toast.sendDetailsToSupport")}
      </BugButton>
    </BugButtonWrapper>
  );
};

export default ToastReportBugButton;
