import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { SearchInput } from "@/components/SearchInput";

import { IconType, InputIconColor } from "@/enums";
import { BooksTransactionsHistorySearchValues } from "@/types/BooksForms/Search";

import { CompactDateRangeFilter } from "./CompactSearchComponents";
import {
  CloseIcon,
  CloseIconWrapper,
  Content,
  ContentWrapper,
  FilterCounter,
  FilterFullWidthButton,
  FilterIcon,
  FiltersContainer,
  FilterSegmentButton,
  FiltersToggleButton,
  MenuIcon,
} from "./Search.styles";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { getFormattedDate } from "@/utils/formatDate";

import { useAuthStore, useStorageStore } from "@/store";
import { getDayjsLocale } from "@/translations/dayjs-locale";

const createDate = (days: number, months: number, years: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  date.setMonth(date.getMonth() + months);
  date.setFullYear(date.getFullYear() + years);
  return date;
};

interface CompactSearchProps {
  formOptions: FormikProps<BooksTransactionsHistorySearchValues>;
  handleSetResetCompactFunction: (func: () => void) => void;
}

const CompactSearch: FC<CompactSearchProps> = ({
  formOptions,
  handleSetResetCompactFunction,
}) => {
  const {
    values,
    initialValues,
    handleSubmit,
    handleChange,
    handleReset,
    setFieldValue,
  } = formOptions;
  const {
    userAuth: { display_weight_in: units },
  } = useAuthStore();
  const { shops } = useStorageStore();

  const { t, i18n } = useTranslation("common");

  const locale = getDayjsLocale(i18n.language);

  const filterTypes = {
    date: "date",
    amount: "amount",
  };

  const today = new Date();
  const minDate = createDate(0, 0, -2);
  const maxDate = today;

  const initialDateRange = {
    startDate: today,
    endDate: today,
    key: "selection",
  };

  const [isFilter, setIsFilter] = useState(false);
  const [openedFilter, setOpenedFilter] = useState<string | null>(null);
  const [selectionRange, setSelectionRange] = useState(initialDateRange);

  const filterProps = useMemo(
    () => ({
      values,
      initialValues,
      handleSubmit,
      handleChange,
      setFieldValue,
    }),
    [values, initialValues, handleSubmit, handleChange, setFieldValue],
  );

  const toggleFilterMenu = useCallback(() => {
    setIsFilter(!isFilter);
  }, [isFilter, setIsFilter]);

  const handleSelectedFilter = useCallback(
    (filterName: string | null) => {
      if (openedFilter === filterName) {
        setOpenedFilter(null);
      } else {
        setOpenedFilter(filterName);
      }
    },
    [openedFilter, setOpenedFilter],
  );

  const handleDateSelection = useCallback(
    (range: any) => {
      setSelectionRange(range["selection"]);
    },
    [setSelectionRange],
  );

  const hanldeApplyDateRange = useCallback(() => {
    handleSelectedFilter(null);
    setFieldValue("date", selectionRange);
    handleSubmit();
  }, [selectionRange, setFieldValue, handleSubmit, handleSelectedFilter]);

  const handleClearDateRange = () => {
    //FIXME: If we don't want to close date filter, but then it's not obvious if clear was successful
    // event.stopPropagation();
    setFieldValue("date", {});
    handleSubmit();
  };

  const resetInternalValues = useCallback(() => {
    setSelectionRange(initialDateRange);
  }, [setSelectionRange]);

  const handleResetClick = useCallback(() => {
    handleSelectedFilter(null);
    setIsFilter(false);
    resetInternalValues();
    handleReset();
    handleSubmit();
  }, [handleReset, handleSubmit, handleSelectedFilter, resetInternalValues]);

  useEffect(() => {
    handleSetResetCompactFunction(() => handleResetClick);
  }, [handleResetClick, handleSetResetCompactFunction]);

  useEffect(() => {
    if (!isFilter) handleResetClick();
  }, [isFilter]);

  const searchContent = (
    <SearchInput
      name="description"
      value={values.description}
      initialValue={initialValues.description}
      placeholder={t("books.search.filterByDescription")}
      {...filterProps}
    />
  );

  const activeFiltersCount = () => {
    const date = values.date;
    const isRange = typeof date === "object";
    const startDate = isRange && date.startDate;
    const endDate = isRange && date.endDate;

    const hasSelectedDateRange = !!startDate && !!endDate;
    const activeFiltersCount = [hasSelectedDateRange].filter((x) => x).length;

    return activeFiltersCount === 0 ? null : activeFiltersCount;
  };

  const dateFilterButton = () => {
    const date = values.date;
    const isRange = typeof date === "object";
    const startDate = isRange && date.startDate;
    const endDate = isRange && date.endDate;

    const hasSelectedDateRange = !!startDate && !!endDate;
    let title = t("parcels.search.date");
    if (hasSelectedDateRange) {
      const startTitle = getFormattedDate(startDate, locale, "MMM DD");
      const endTitle = getFormattedDate(endDate, locale, "MMM DD");
      title = `${startTitle} - ${endTitle}`;
    }

    return (
      <FilterSegmentButton
        selected={hasSelectedDateRange}
        onClick={() => handleSelectedFilter(filterTypes.date)}
      >
        {title}
      </FilterSegmentButton>
    );
  };

  const amountFilterButton = () => {
    const amountButtonTitle = values.amount
      ? `${values.amount}`
      : t("books.search.filterByAmount");
    return (
      <FilterSegmentButton
        selected={!!values.amount}
        onClick={() => handleSelectedFilter(filterTypes.amount)}
      >
        {amountButtonTitle}
      </FilterSegmentButton>
    );
  };

  const allFiltersContent = (
    <FiltersContainer>
      {dateFilterButton()}
      {amountFilterButton()}
    </FiltersContainer>
  );

  const selectedDateFilterContent = (
    <FiltersContainer>
      <FilterFullWidthButton
        onClick={() => handleSelectedFilter(filterTypes.date)}
      >
        {t("parcels.search.date")}
        {typeof values.date !== "string" &&
          values.date.startDate &&
          values.date.endDate && (
            <CloseIconWrapper onClick={handleClearDateRange}>
              <CloseIcon type={IconType.Close} color={InputIconColor.Red} />
            </CloseIconWrapper>
          )}
      </FilterFullWidthButton>
      <CompactDateRangeFilter
        language={i18n.language}
        minDate={minDate}
        maxDate={maxDate}
        selectionRange={selectionRange}
        handleDateSelection={handleDateSelection}
        hanldeApplyDateRange={hanldeApplyDateRange}
      />
    </FiltersContainer>
  );

  const selectedAmountFilterContent = (
    <FiltersContainer>
      <SearchInput
        name="amount"
        value={values.amount}
        initialValue={initialValues.amount}
        placeholder={t("books.search.filterByAmount")}
        type="number"
        {...filterProps}
      />
      <FilterSegmentButton
        onClick={() => handleSelectedFilter(filterTypes.amount)}
      >
        {t("books.search.filterByAmount")}
      </FilterSegmentButton>
    </FiltersContainer>
  );

  const filtersContent = () => {
    if (openedFilter === null) {
      return allFiltersContent;
    } else {
      switch (openedFilter) {
        case filterTypes.date:
          return selectedDateFilterContent;
        case filterTypes.amount:
          return selectedAmountFilterContent;
        default:
          return allFiltersContent;
      }
    }
  };

  return (
    <ContentWrapper>
      <Content>{isFilter ? filtersContent() : searchContent}</Content>
      <FiltersToggleButton onClick={toggleFilterMenu}>
        <FilterIcon type={IconType.Filter} />
        <MenuIcon type={IconType.Menu} />
        {activeFiltersCount() && (
          <FilterCounter>{activeFiltersCount()}</FilterCounter>
        )}
      </FiltersToggleButton>
    </ContentWrapper>
  );
};

export default CompactSearch;
