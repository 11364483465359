import {
  AuthChangePasswordDto,
  AuthChangePasswordResponseDto,
  AuthPasswordResetDto,
  AuthPasswordResetResponseDto,
  AuthSignInDto,
  AuthSignUpDto,
  AuthSignUpResponseDto,
  AuthUpdateProfileDto,
  AuthUserDto,
} from "types/api/auth";

import axiosInstance from "./axios_instance";

const AuthAPI = {
  signIn: async (data: AuthSignInDto): Promise<AuthSignUpResponseDto> => {
    const res = await axiosInstance.post("/auth/sign_in", data);
    return res.data;
  },
  logOut: () => axiosInstance.get("/auth/sign_out"),
  signUp: async (data: AuthSignUpDto): Promise<AuthSignUpResponseDto> => {
    const res = await axiosInstance.post("/auth", data);
    return res.data;
  },
  getProfile: async (): Promise<AuthUserDto> => {
    const res = await axiosInstance.get("/user");
    return res.data;
  },
  requestPasswordReset: async (
    data: AuthPasswordResetDto,
  ): Promise<AuthPasswordResetResponseDto> => {
    const res = await axiosInstance.post("/auth/password", data);
    return res.data;
  },
  changePassword: async (
    data: AuthChangePasswordDto,
  ): Promise<AuthChangePasswordResponseDto> => {
    const res = await axiosInstance.patch("/auth/password", data);
    return res.data;
  },
  updateProfile: async (
    data: AuthUpdateProfileDto,
  ): Promise<AuthSignUpResponseDto> => {
    const res = await axiosInstance.patch("/auth", data);
    return res.data;
  },
};

export default AuthAPI;
