import { FC, useCallback, useEffect, useRef } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Tab, TabsView } from "@/containers/RightPanel/components";
import {
  BackArrow,
  ContentWrapper,
  Heading,
} from "@/containers/RightPanel/RightPanel.styles";
import { Button } from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";

import { Spinner } from "@/styles/common";

import {
  useCreateAddresses,
  useUpdateAddresses,
} from "@/hooks/react-query/destinations";
import { useOutgoingUpdateAddress } from "@/hooks/react-query/outgoing";

import useOutgoingStore from "@/store/useOutgoingStore";
import { AddressFormPanelData } from "@/store/useRightPanelStore";
import { useAuthStore, useRightPanelStore } from "@/store";
import { ButtonColor, ButtonType, ButtonVariant, IconType } from "@/enums";

import {
  addressForm,
  getAreAllRequiredFieldsValid,
  supportedCountryPhoneMasks,
} from "../addressFormHelper";
import Address from "./Address";
import AddressCustomSender from "./AddressCustomSender";
import {
  ButtonsWrapper,
  Content,
  CustomSenderErrorMsg,
  FormWrapper,
  InfoText,
  InfoWrapper,
} from "./AddressForm.styles";

interface AddressFormProps {
  isOpen: boolean;
  panelData: AddressFormPanelData;
}

const AddressForm: FC<AddressFormProps> = ({ panelData, isOpen }) => {
  const { t } = useTranslation("common");
  const {
    userAuth: { locale: currentLocale },
  } = useAuthStore();
  const { mutateAsync: createAddress } = useCreateAddresses();
  const { mutateAsync: updateAddress } = useUpdateAddresses();
  const { mutateAsync: updateAddressOutgoing } = useOutgoingUpdateAddress();
  const { closeRightPanel, openRightPanel } = useRightPanelStore();
  const { isLoadingDetailedItem, detailedItem } = useOutgoingStore();
  const isOutgoingUpdateAddress = panelData.isOutgoingUpdateAddress;

  const formProps = useFormik(
    addressForm(
      t,
      panelData,
      openRightPanel,
      closeRightPanel,
      createAddress,
      isOutgoingUpdateAddress ? updateAddressOutgoing : updateAddress,
      detailedItem,
      currentLocale,
    ),
  );

  const { values, errors, handleSubmit, handleReset, isSubmitting } = formProps;

  const formRef = useRef(null);
  const isEditForm = panelData.isEditForm;
  const showDetailsTabRecipientEditForm = panelData.isRecipientEditForm;
  const returnPreviousPanel = panelData.returnPreviousPanel;
  const handleBack = useCallback(
    () =>
      returnPreviousPanel
        ? openRightPanel(returnPreviousPanel, panelData.initialPanelData ?? {})
        : null,
    [panelData, returnPreviousPanel, openRightPanel],
  );
  const country = values.address.country;
  const showNativeSections =
    country &&
    country.value !== "US" &&
    supportedCountryPhoneMasks.indexOf(country.value ?? "") !== -1;

  useEffect(() => {
    if (!isOpen) handleReset(null);
  }, [isOpen]);

  const areAllRequiredFieldsValid = getAreAllRequiredFieldsValid(
    values,
    errors,
  );

  return (
    <ContentWrapper>
      <Heading>
        {returnPreviousPanel && (
          <button onClick={handleBack}>
            <BackArrow type={IconType.Arrow} />
          </button>
        )}
        {isEditForm ? t("account.addressEditing") : t("account.addingAddress")}
      </Heading>
      <Spinner isActive={isLoadingDetailedItem} />
      {!isLoadingDetailedItem && (
        <FormWrapper ref={formRef} onSubmit={handleSubmit}>
          <TabsView openTab={panelData.openTab}>
            <Tab title={t("account.address")}>
              <Content>
                <Address
                  {...formProps}
                  currentLocale={currentLocale}
                  showNativeSections={showNativeSections}
                />
              </Content>
            </Tab>
            {showNativeSections && (
              <Tab
                title={
                  areAllRequiredFieldsValid ? (
                    t("account.customSenderTitle")
                  ) : (
                    <Tooltip
                      body={
                        <CustomSenderErrorMsg>
                          {t("account.customSenderDisabledTooltip")}
                        </CustomSenderErrorMsg>
                      }
                    >
                      {t("account.customSenderTitle")}
                    </Tooltip>
                  )
                }
                disabled={!areAllRequiredFieldsValid}
              >
                <Content>
                  <InfoWrapper>
                    <InfoText>{t("account.customSenderDesc")}</InfoText>
                  </InfoWrapper>
                  <AddressCustomSender {...formProps} />
                </Content>
              </Tab>
            )}
            {showDetailsTabRecipientEditForm && (
              <Tab title={t("account.details")}>
                <Content></Content>
              </Tab>
            )}
          </TabsView>
          <ButtonsWrapper>
            <Button
              onClick={returnPreviousPanel ? handleBack : closeRightPanel}
            >
              {t("common.cancel")}
            </Button>
            <Button
              isLoading={isSubmitting}
              disabled={isSubmitting}
              type={ButtonType.Submit}
              color={ButtonColor.Primary}
              variant={ButtonVariant.Filled}
            >
              {isEditForm
                ? t("common.saveChanges")
                : t("common.addAddress", { word: t("common.new") })}
            </Button>
          </ButtonsWrapper>
        </FormWrapper>
      )}
    </ContentWrapper>
  );
};

export default AddressForm;
