import React, { FC, memo, useCallback, useState } from "react";

import { useFormik } from "formik";
import { Hidden, Visible } from "react-grid-system";

import { LoadingBar } from "@/components/LoadingBar";

import { ListHeader } from "@/styles/parcels";

import { useBooksTransactionsHistory } from "@/hooks/react-query/books";

import { BooksTransactionsHistoryParamsDto } from "@/types/api/books";
import { BooksTransactionsHistorySearchValues } from "@/types/BooksForms/Search";

import { BooksTransactionsHistoryList } from "./components/BooksTransactionsHistoryList";
import { CompactSearch, Search } from "./components/Search";
import { SearchPlaceholder } from "./components/SearchPlaceholder";
import { booksTransactionsHistorySearchFormik } from "./helpers/booksTransactionsHistorySearchFormik";
import { StyledGrid, Wrapper } from "./styles";

const defaultBooksTransactionsHistoryParams: BooksTransactionsHistoryParamsDto =
  {
    page: 1,
    filter: null,
  };

const BooksTransactionsHistory: FC = () => {
  const [fetchParamsDto, setFetchParamsDto] =
    useState<BooksTransactionsHistoryParamsDto>(
      defaultBooksTransactionsHistoryParams,
    );
  const [handleResetCompactFunction, setHandleResetCompactFunction] =
    useState<Function>(() => null);

  const {
    data: historyItems,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useBooksTransactionsHistory(fetchParamsDto);

  const hasItems = historyItems && historyItems.length > 0;
  const hasFilters = !!fetchParamsDto.filter;

  const handleSubmit = (values: BooksTransactionsHistoryParamsDto) => {
    setFetchParamsDto(values);
  };

  const formOptions = useFormik<BooksTransactionsHistorySearchValues>(
    booksTransactionsHistorySearchFormik(handleSubmit),
  );

  const handleFetchNextPage = () => {
    if (hasNextPage) void fetchNextPage();
  };

  const handleResetClick = useCallback(() => {
    setFetchParamsDto(defaultBooksTransactionsHistoryParams);
    formOptions.resetForm();
  }, [setFetchParamsDto, defaultBooksTransactionsHistoryParams, formOptions]);

  const handleResetClickCompactSearch = () => {
    handleResetCompactFunction();
  };

  const handleSetResetCompactFunction = (func: Function) =>
    setHandleResetCompactFunction(func);

  return (
    <Wrapper>
      <StyledGrid fluid>
        <LoadingBar isLoading={isLoading} />
        <ListHeader>
          <Hidden xs>
            <Search formOptions={formOptions} />
          </Hidden>
          <Visible xs>
            <CompactSearch
              formOptions={formOptions}
              handleSetResetCompactFunction={handleSetResetCompactFunction}
            />
          </Visible>
        </ListHeader>
        {hasItems && (
          <BooksTransactionsHistoryList
            historyItems={historyItems}
            disabled={isLoading}
            onFetchNextPage={handleFetchNextPage}
            hasMore={hasNextPage}
          />
        )}
        {!hasItems && !isLoading && (
          <SearchPlaceholder
            {...(hasFilters && { handleReset: handleResetClick })}
          />
        )}
      </StyledGrid>
    </Wrapper>
  );
};

export default memo(BooksTransactionsHistory);
