import {
  InfiniteData,
  QueryObserverResult,
  RefetchOptions,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import StorageAPI from "@/api/storage_api";

import formatter from "@/utils/formatter";
import { transformStorageItems } from "@/hooks/react-query/storage";

import { StorageItem } from "@/store/useStorageStore";
import { useStorageStore } from "@/store";
import { StorageParamsDto, StorageResponseDto } from "@/types/api/shipments";
import { StorageSearchValues } from "@/types/StorageForms/Search";

const convertingToUTCDate = (date: Date) =>
  date &&
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

const getDateFilter = (value: string | { startDate: Date; endDate: Date }) => {
  switch (value) {
    case "today":
      return { "q[gt_days_period]": 1 };
    case "yesterday":
      return { "q[gt_days_period]": 2, "q[lt_days_period]": 1 };
    case "lastWeek":
      return { "q[gt_days_period]": 7 };
    case "lastMonth":
      return { "q[gt_months_period]": 1 };
    default:
      return typeof value === "object"
        ? {
            "q[date_received_at_gteq]": convertingToUTCDate(value.startDate),
            "q[date_received_at_lteq]": convertingToUTCDate(value.endDate),
          }
        : {};
  }
};

// 25kg = 55lbs
export const getMaxWeightValue = (units: string) => (units === "kg" ? 25 : 55);

export const storageFormikSearchValues = {
  weightRange: [],
  date: "",
  vendor: null,
  location: "",
  trackingNumber: "",
};

export const searchFormik = (
  onGetStorageSubmit: (values: StorageParamsDto) => void,
  onGetInventoryItemsSubmit: (values: StorageParamsDto) => void,
  refetchStorage: (
    options?: RefetchOptions,
  ) => Promise<
    QueryObserverResult<
      InfiniteData<AxiosResponse<StorageResponseDto[]>>,
      Error
    >
  >,
  initialValues: typeof storageFormikSearchValues,
) => ({
  initialValues,
  onSubmit: async (values: StorageSearchValues) => {
    const { weightRange, date, vendor, location, trackingNumber } = values;
    const {
      totalStoragePage,
      updateStorageItems,
      updateStorageTotalAmount,
      updateStorageTotalWeight,
      updateIsLoadingStorage,
      updateStorageSearchValues,
      updateIsNavigateFromInventory,
    } = useStorageStore.getState();

    const dateFilter = getDateFilter(date);

    if (trackingNumber.trim() === ">") {
      updateIsNavigateFromInventory(true);
      updateIsLoadingStorage(true);
      let storageItems: StorageItem[] = [];
      for (let page = 1; page <= +totalStoragePage; page++) {
        const paramsDto = {
          page: page,
          filter: {
            "q[weight_gteq]": weightRange && weightRange[0],
            "q[weight_lteq]": weightRange && weightRange[1],
            "q[vendor_eq]": vendor && vendor.value,
            "q[location_in]": location || null,
            ...dateFilter,
          },
        };

        const res = await StorageAPI.getStorage(paramsDto);

        storageItems = [...storageItems, ...transformStorageItems(res.data)];
      }

      const storageItemsWithInventory = storageItems.filter(
        (item) => item.items_quantity > 1,
      );
      const totalWeight = storageItemsWithInventory.reduce(
        (sum, item) => sum + item.weight,
        0,
      );

      updateStorageTotalAmount(storageItemsWithInventory.length);
      updateStorageTotalWeight(formatter.roundTo2Decimal(totalWeight));
      updateStorageItems(storageItemsWithInventory);
      updateIsLoadingStorage(false);
      updateStorageSearchValues(values as typeof storageFormikSearchValues);

      return;
    }

    if (
      trackingNumber &&
      (trackingNumber.startsWith(">") || trackingNumber.startsWith(">>"))
    ) {
      const isContains = trackingNumber.startsWith(">");
      const itemsSearchTerm = trackingNumber.slice(isContains ? 2 : 3);

      onGetInventoryItemsSubmit({
        page: 1,
        filter: {
          ...(isContains && {
            description_or_customer_comment_cont: itemsSearchTerm,
          }),
          ...(!isContains && {
            "q[description_or_customer_comment_eq]": itemsSearchTerm,
          }),
        },
      });
    } else {
      updateIsLoadingStorage(true);
      onGetStorageSubmit({
        page: 1,
        filter: {
          "q[descriptions_or_customer_comment_cont]": trackingNumber || null,
          "q[weight_gteq]": weightRange && weightRange[0],
          "q[weight_lteq]": weightRange && weightRange[1],
          "q[vendor_eq]": vendor && vendor.value,
          "q[location_in]": location || null,
          ...dateFilter,
        },
      });

      refetchStorage();
    }
  },

  displayName: "StorageSearch",
});
