import { AxiosResponse } from "axios";
import { CustomsDeclaration as CustomDeclarationResponseDto } from "types/api/common";
import {
  HistoryDetailedParamsDto,
  HistoryDetailedResponseDto,
  HistoryFullDetailedItemResponseDto,
  HistoryResponseDto,
} from "types/api/history";

import { ShipmentResponseDto } from "@/types/api/shipments";

import axiosInstance from "./axios_instance";

const HistoryAPI = {
  getHistory: async (year: number): Promise<HistoryResponseDto> => {
    const res = await axiosInstance.get("/history/orders", {
      params: { year },
    });
    return res.data;
  },
  getHistoryDetailed: ({
    week_number,
    shipping_method,
    year,
    page,
  }: HistoryDetailedParamsDto): Promise<
    AxiosResponse<HistoryDetailedResponseDto[]>
  > => {
    return axiosInstance.get(`/history/orders/week`, {
      params: { week_number, shipping_method, year, page },
    });
  },
  getHistoryFullDetailedItem: async (
    id: number,
  ): Promise<HistoryFullDetailedItemResponseDto> => {
    const res = await axiosInstance.get(`/consolidations/${id}`);

    return res.data;
  },
  getCustomsDeclarations: async (
    id: number,
  ): Promise<CustomDeclarationResponseDto[]> => {
    const res = await axiosInstance.get(
      `/consolidations/${id}/customs_informations`,
    );

    return res.data;
  },
  getShipment: async (id: number): Promise<ShipmentResponseDto> => {
    const res = await axiosInstance.get(`/shipments/${id}`);

    return res.data;
  },
};

export default HistoryAPI;
