import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { FormikProps } from "formik";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

import { DatePicker } from "@/components/DatePicker";

import { getDayjsLocale } from "@/translations/dayjs-locale";
import { DateFilterType } from "@/types/common";

import SearchFilter from "../SearchFilter";
import {
  DateButton,
  DatesDivider,
  DatesErrorWrapper,
  DatesWrapper,
  ExactContainer,
  ExactTitle,
  Wrapper,
} from "./Filter.styles";

const FilterDate = <T extends { date: DateFilterType }>({
  initialValues,
  setFieldValue,
  values,
  handleSubmit,
  ...otherProps
}: FormikProps<T>) => {
  const toDatePickerRef = useRef<HTMLInputElement>(null);
  const currentDate = new Date();
  const { t, i18n } = useTranslation("common");
  const locale = getDayjsLocale(i18n.language);
  const value = values.date;
  const initialValue = initialValues.date;
  const isRange = typeof value === "object";
  const startValue = isRange && value.startDate;
  const endDate = isRange && value.endDate;
  const [innerValue, setInnerValue] = useState(value);
  const isInnerValueRange = typeof innerValue === "object";
  const isDateChanged = !isEqual(value, initialValue);

  const isWrongDates =
    typeof innerValue === "object"
      ? (innerValue.startDate === null && innerValue.endDate === null) ||
        (innerValue.endDate && innerValue.endDate > new Date()) ||
        (innerValue.startDate && innerValue.startDate > new Date()) ||
        (!!innerValue.startDate &&
          !!innerValue.endDate &&
          innerValue.endDate < innerValue.startDate)
      : false;

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const getFormattedDate = useCallback(
    (value: Date | number) => {
      if (!value) return "";
      return dayjs(value).locale(locale).format("LL");
    },
    [locale],
  );

  const getDatesTitle = useCallback(() => {
    const formattedStart = startValue && getFormattedDate(startValue);
    const formattedEnd = endDate && getFormattedDate(endDate);

    if (!isRange) return t(`parcels.search.date`);
    if (formattedEnd === formattedStart && formattedStart) return formattedEnd;
    if (endDate && startValue) return `${formattedStart} — ${formattedEnd}`;
    if (startValue) return `${t("parcels.search.dateFrom")} ${formattedStart}`;
    if (endDate) return `${t("parcels.search.dateTill")} ${formattedEnd}`;
    return t("parcels.search.date");
  }, [endDate, getFormattedDate, isRange, startValue, value, t]);

  const dropInnerValue = useCallback(() => setInnerValue(value), [value]);

  const applyInnerValue = () => {
    if (isWrongDates) {
      setInnerValue(value);
      return;
    }

    void setFieldValue("date", innerValue);
  };

  const handleInnerStartChange = useCallback(
    (valueDate: Date) => {
      if (isRange) {
        setInnerValue({ ...value, startDate: valueDate });
      } else if (isEmptyValue || isInnerValueRange) {
        setInnerValue({
          startDate: valueDate,
          endDate: innerValue && innerValue.endDate ? innerValue.endDate : null,
        });
      }

      if (toDatePickerRef.current && toDatePickerRef.current.focus) {
        toDatePickerRef.current.focus();
      }
    },
    [currentDate, innerValue, setInnerValue],
  );

  const handleInnerEndChange = useCallback(
    (valueDate: Date) => {
      if (isRange) {
        setInnerValue({ ...value, endDate: valueDate });
      } else if (isEmptyValue || isInnerValueRange) {
        setInnerValue({
          startDate:
            innerValue && innerValue.startDate ? innerValue.startDate : null,
          endDate: valueDate,
        });
      }
    },
    [currentDate, innerValue, setInnerValue],
  );

  const setDefault = useCallback(() => {
    void setFieldValue("date", initialValue);
    dropInnerValue();
  }, [dropInnerValue, initialValue, setFieldValue]);

  const handleQuickOption = (optionName: string) => {
    setInnerValue(optionName !== innerValue ? optionName : "");
  };

  const setToday = useCallback(
    () => handleQuickOption("today"),
    [handleQuickOption],
  );

  const setYesterday = useCallback(
    () => handleQuickOption("yesterday"),
    [handleQuickOption],
  );

  const setWeek = useCallback(
    () => handleQuickOption("lastWeek"),
    [handleQuickOption],
  );

  const setMonth = useCallback(
    () => handleQuickOption("lastMonth"),
    [handleQuickOption],
  );

  const dateTitle = useMemo(() => getDatesTitle(), [getDatesTitle]);
  const isEmptyValue = innerValue === "";

  return (
    <SearchFilter
      applyInnerValue={applyInnerValue}
      dropInnerValue={dropInnerValue}
      handleSubmit={handleSubmit}
      setDefault={setDefault}
      title={dateTitle}
      innerTitle={t("parcels.search.selectDate")}
      isActive={isDateChanged}
      isApplyDisabled={isWrongDates}
      {...otherProps}
    >
      <Wrapper>
        <DateButton onClick={setToday} $isSelected={innerValue === "today"}>
          {t("parcels.search.today")}
        </DateButton>
        <DateButton
          onClick={setYesterday}
          $isSelected={innerValue === "yesterday"}
        >
          {t("parcels.search.yesterday")}
        </DateButton>
        <DateButton onClick={setWeek} $isSelected={innerValue === "lastWeek"}>
          {t("parcels.search.lastWeek")}
        </DateButton>
        <DateButton onClick={setMonth} $isSelected={innerValue === "lastMonth"}>
          {t("parcels.search.lastMonth")}
        </DateButton>
        {(typeof innerValue === "object" || isEmptyValue) && (
          <ExactContainer>
            <ExactTitle>{t("parcels.search.exactDates")}</ExactTitle>
            <DatesWrapper>
              <DatePicker
                selectsStart
                value={
                  !isEmptyValue ? (innerValue.startDate?.toString() ?? "") : ""
                }
                startDate={!isEmptyValue ? innerValue.startDate : null}
                endDate={!isEmptyValue ? innerValue.endDate : null}
                onChange={handleInnerStartChange}
                placeholderText={t("common.from")}
              />
              <DatesDivider />
              <DatePicker
                selectsEnd
                value={
                  !isEmptyValue ? (innerValue.endDate?.toString() ?? "") : ""
                }
                startDate={!isEmptyValue ? innerValue.startDate : null}
                endDate={!isEmptyValue ? innerValue.endDate : null}
                onChange={handleInnerEndChange}
                placeholderText={t("common.to")}
                datePickerRef={toDatePickerRef}
                invalid={isWrongDates}
              />
            </DatesWrapper>

            {isWrongDates && (
              <DatesErrorWrapper>
                {t("parcels.search.dateRangeError")}
              </DatesErrorWrapper>
            )}
          </ExactContainer>
        )}
      </Wrapper>
    </SearchFilter>
  );
};

export default memo(FilterDate);
