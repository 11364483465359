import axios from "axios";
import { redirect } from "react-router-dom";
import { AuthData, getAuthData, setAuthData } from "utils/authData";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (config) => {
  const { origin } = window.location;

  if (origin.includes("localhost") && process.env.NODE_ENV === "development") {
    // Development Environment
    config.baseURL = process.env.REACT_APP_API_DEV_BASE_URL;
  } else if (
    origin.includes("192.168") ||
    origin.includes("staging") ||
    origin.includes("beta-app") ||
    origin.includes("brocoders.sklad")
  ) {
    // Staging Environment
    config.baseURL = process.env.REACT_APP_API_STAGE_BASE_URL;
    config.headers.Authorization = process.env.REACT_APP_STAGE_BEARER_TOKEN;
  } else {
    // Production Environment
    config.baseURL = process.env.REACT_APP_API_PROD_BASE_URL;
    config.headers.Authorization = process.env.REACT_APP_PROD_BEARER_TOKEN;
  }

  return config;
});

axiosInstance.interceptors.request.use((request) => {
  Object.assign(request.headers, getAuthData());
  return request;
});

axiosInstance.interceptors.response.use(
  async (res) => {
    const { request, headers } = res;
    if (
      request.responseURL.includes("/auth") &&
      headers &&
      headers["access-token"]
    ) {
      setAuthData(headers as Partial<AuthData>);
    }

    return res;
  },
  (error) => {
    const { response = {} } = error;
    const { headers, status } = response;
    const { pathname } = window.location;

    if (
      status === 401 &&
      !(pathname.includes("signin") || pathname.includes("signup"))
    ) {
      redirect(`${pathname}?signInModal`);
    }

    if (headers && headers["access-token"]) setAuthData(headers);

    return Promise.reject(error);
  },
);

export default axiosInstance;
