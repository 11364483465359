import {
  ChangeEvent,
  FC,
  FocusEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
} from "react";

import {
  CheckboxCustom,
  CheckboxField,
  CheckboxIcon,
  CheckboxLabel,
  CheckboxText,
  Spinner,
} from "./CheckBox.style";

import { CheckboxTooltipWrapper } from "./components";

interface CheckboxProps {
  label?: ReactNode | string;
  disabled?: boolean;
  isLoading?: boolean;
  invalid?: boolean;
  icon?: JSX.Element | JSX.Element[];
  onClick?: (e: MouseEvent<HTMLLabelElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<Element, Element>) => void;
  customPaddingValue?: number;
  tooltipContent?: ReactElement | null;
  checked?: boolean;
  name?: string;
}

const Checkbox: FC<CheckboxProps> = ({
  label = "",
  disabled = false,
  isLoading = false,
  icon,
  customPaddingValue,
  invalid = false,
  tooltipContent = null,
  onClick,
  ...otherProps
}) => {
  return (
    <CheckboxLabel onClick={onClick} $invalid={invalid}>
      <Spinner isActive={isLoading} />
      <CheckboxField
        type="checkbox"
        data-testId="checkbox"
        disabled={disabled}
        {...otherProps}
      />
      <CheckboxCustom
        disabled={disabled}
        $customPaddingValue={customPaddingValue}
      />
      <CheckboxTooltipWrapper tooltipContent={tooltipContent}>
        {icon && <CheckboxIcon>{icon}</CheckboxIcon>}
        {label && <CheckboxText>{label}</CheckboxText>}
      </CheckboxTooltipWrapper>
    </CheckboxLabel>
  );
};

export default Checkbox;
