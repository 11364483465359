import pathOr from "@ramda/pathor";
import { QueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import * as yup from "yup";

import ServicesRequestAPI from "@/api/servicesRequest_api";

import { toastResponseError } from "@/utils/responseMessageHelper";

import {
  RightPanelType,
  StorageItemPanelData,
} from "@/store/useRightPanelStore";
import { requestNames } from "@/store/useServicesRequestStore";
import { addServiceRequest } from "@/store/useStorageStore";
import {
  CancelServiceRequestDto,
  CreateServiceRequestDto,
  RequestTransferDto,
  ServiceRequestsResponseDto,
} from "@/types/api/service_requests";
import { OpenRightPanel } from "@/types/common/rightPanel";
import {
  ServiceRequestFormHelpers,
  ServiceRequestFormValues,
  ServiceRequestProps,
} from "@/types/StorageForms/ServiceRequest";
import { StorageQueryKey } from "@/types";

const cancelableStates = [
  "translated",
  "pending_translation",
  "pending_moderation",
  "warehouse_requested",
  "pending_requestInventoryShipment",
];

const isUnprocessedRequest = (serviceRequests: string[] = []) =>
  serviceRequests.find((name) => name === "unprocessed");

const getRequestInventoryInfo = (unpackingTask: any) => {
  const isRequestActive = unpackingTask.state === "requested";

  return {
    hasRequest: isRequestActive,
    isSubmitHidden: false,
    isRequestCancelable: isRequestActive,
    processed: false,
    service_request_type: {
      type: "requestInventoryShipment",
    },
    state: "pending_requestInventoryShipment",
  };
};

const getRequestInfo = (requests: any[] = [], requestType: string) => {
  const request =
    requests.find(
      (request) =>
        requestNames[
          request.service_request_type.type.toLowerCase() as keyof typeof requestNames
        ] === requestType,
      null,
    ) || {};

  const hasRequest = !!request.id;
  const isRequestCancelable =
    hasRequest && cancelableStates.includes(request.state as string);
  const processed = hasRequest && request.processed;

  const isSubmitHidden = hasRequest ? !isRequestCancelable || processed : false;

  return {
    ...request,
    hasRequest,
    isSubmitHidden,
    isRequestCancelable,
    processed,
  };
};

const handleRequestSubmit = (
  values: ServiceRequestFormValues,
  {
    setSubmitting,
    props: {
      panelData,
      openRightPanel,
      serviceTypes,
      createRequestTransferShipment,
      completeTransferShipment,
      queryClient,
    },
  }: ServiceRequestFormHelpers,
) => {
  const requestType = panelData.requestType;

  if (requestType === "requestTransferShipment") {
    const id = panelData.id!;
    const partnerTransferId = values.partnerTransferId ?? "";
    const isInventoryItemVariant = panelData.isInventoryItemVariant;
    const isClientTransferring = panelData.state === "client_transferring";

    isClientTransferring
      ? completeTransferShipment({ partnerTransferId })
          .then(() => {
            setSubmitting(false);
          })
          .catch((error) => {
            toastResponseError(error);
            setSubmitting(false);
          })
      : createRequestTransferShipment({
          id,
          partnerTransferId,
          ...(isInventoryItemVariant && {
            sku: panelData?.originalItem?.sku,
          }),
        })
          .then(() => {
            queryClient.invalidateQueries({
              queryKey: [StorageQueryKey.Storage],
            });
            setSubmitting(false);
          })
          .catch((error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
  } else if (requestType === "requestInventoryShipment") {
    const id = panelData.requestInventoryShipmentData?.items[0].id ?? null;

    const unpackingServiceRequest =
      panelData.requestInventoryShipmentData?.unpacking_task ?? {};

    const status = unpackingServiceRequest.state;

    if (status === "requested") {
      ServicesRequestAPI.cancelRequestInventoryServiceRequest({
        taskId: unpackingServiceRequest.id,
      })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: [StorageQueryKey.Storage],
          });
          openRightPanel(
            RightPanelType.STORAGE_ITEM,
            panelData as StorageItemPanelData,
          );
        })
        .catch((error) => {
          toastResponseError(error);
          setSubmitting(false);
        });
    } else {
      ServicesRequestAPI.createRequestInventoryShipment({ id })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: [StorageQueryKey.Storage],
          });
          openRightPanel(
            RightPanelType.STORAGE_ITEM,
            panelData as StorageItemPanelData,
          );
        })
        .catch((error) => {
          toastResponseError(error);
          setSubmitting(false);
        });
    }
  } else {
    const currentRequest = panelData?.requests?.find(
      (request: any) =>
        requestNames[
          request.service_request_type.type.toLowerCase() as keyof typeof requestNames
        ] === requestType,
    );

    const serviceInfo = serviceTypes?.find(
      (service) =>
        requestNames[service.type as keyof typeof requestNames] === requestType,
    );

    const data = currentRequest
      ? { itemId: panelData.id!, requestId: currentRequest.id }
      : {
          itemId: panelData.id!,
          service_request_type_id: serviceInfo?.id,
          user_comment: values.comment,
        };

    const handleServiceRequest = (
      data: CreateServiceRequestDto | CancelServiceRequestDto,
    ) => {
      const processResponse = (response: any) => {
        const value = pathOr({}, ["value"], response);
        const data = value.data;
        if (data) {
          addServiceRequest({ packageId: panelData.id!, data });
        }

        queryClient.invalidateQueries({
          queryKey: [StorageQueryKey.Storage],
        });
        openRightPanel(
          RightPanelType.STORAGE_ITEM,
          panelData as StorageItemPanelData,
        );
      };

      const handleError = (error: Error) => {
        toastResponseError(error);
        setSubmitting(false);
      };

      if ("requestId" in data) {
        ServicesRequestAPI.cancelServiceRequest(data)
          .then(processResponse)
          .catch(handleError);
      } else {
        ServicesRequestAPI.createServiceRequest(data)
          .then(processResponse)
          .catch(handleError);
      }
    };

    handleServiceRequest(data);
  }
};

export {
  handleRequestSubmit,
  requestNames,
  getRequestInfo,
  getRequestInventoryInfo,
  cancelableStates,
  isUnprocessedRequest,
};

export const serviceRequestFormik = ({
  t,
  ...props
}: ServiceRequestProps & {
  t: TFunction;
  openRightPanel: OpenRightPanel;
  serviceTypes?: ServiceRequestsResponseDto[];
  createRequestTransferShipment: (data: {
    id: number;
    partnerTransferId: string;
    sku?: string;
  }) => Promise<AxiosResponse<RequestTransferDto>>;
  completeTransferShipment: (data: {
    partnerTransferId: string;
  }) => Promise<unknown>;
  queryClient: QueryClient;
}) => ({
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: {
    comment: "",
    partnerTransferId: "",
  },
  validationSchema: () => {
    const requestType = props.panelData.requestType;

    if (
      requestType === "requestInventoryShipment" ||
      requestType === "additionalPhotos"
    ) {
      return yup.object({});
    } else if (requestType === "requestTransferShipment") {
      return yup.object({
        partnerTransferId: yup
          .string()
          .trim()
          .required(
            t("error.required", { field: t("common.partnerTransferId") }),
          ),
      });
    } else {
      const hasRequest = getRequestInfo(
        props.panelData.requests,
        requestType ?? "",
      ).hasRequest;

      return hasRequest
        ? yup.object({})
        : yup.object({
            comment: yup
              .string()
              .trim()
              .required(t("error.required", { field: t("common.comment") })),
          });
    }
  },
  onSubmit: (
    values: ServiceRequestFormValues,
    formikBag: FormikHelpers<ServiceRequestFormValues>,
  ) => {
    handleRequestSubmit(values, {
      props,
      ...formikBag,
    });
  },
});
