import { create } from "zustand";

import { BookTransactionHistoryItem } from "@/types/api/books";

interface UseBooksStore {
  amountFunds: string;
  transactionsItems: BookTransactionHistoryItem[];
  updateAmountFunds: (amount: string) => void;
  updateTransactionsItems: (items: BookTransactionHistoryItem[]) => void;
}

const initialState = {
  amountFunds: "",
  transactionsItems: [],
};

const useBooksStore = create<UseBooksStore>((set) => ({
  ...initialState,
  updateTransactionsItems: (items) =>
    set(() => ({
      transactionsItems: items,
    })),
  updateAmountFunds: (amount) =>
    set(() => ({
      amountFunds: amount,
    })),
}));

export default useBooksStore;
