import React, { ReactNode, useContext, useEffect, useState } from "react";

import { loadSpace, SpaceApi } from "@usersnap/browser";
import { useTranslation } from "react-i18next";

export const UserSnapContext = React.createContext<SpaceApi | null>(null);

interface UserSnapReportBugProviderProps {
  children: ReactNode;
}

export const UserSnapReportBugProvider = ({
  children,
}: UserSnapReportBugProviderProps) => {
  const [userSnapApi, setUserSnapApi] = useState<SpaceApi | null>(null);
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    loadSpace(process.env.REACT_APP_USERSNAP_PROJECT_API_KEY!).then((api) => {
      api.init({
        collectGeoLocation: "none",
        enableScreenshot: true,
        useLocalStorage: false,
        locale: language,
      });
      setUserSnapApi(api);
    });
  }, [language]);

  return (
    <UserSnapContext.Provider value={userSnapApi}>
      {children}
    </UserSnapContext.Provider>
  );
};

export function useUserSnapApi() {
  return useContext(UserSnapContext);
}
