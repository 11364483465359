import React, { FC, memo } from "react";

import dayjs from "dayjs";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";

import { Icon } from "@/components/Icon";

import { USD } from "@/styles/form";

import { IconType } from "@/enums";
import { BookTransactionHistoryItem } from "@/types/api/books";

import {
  Card,
  CardFooter,
  CardFooterItem,
  CardItem,
  TableCol,
  TableRow,
  Wrapper,
} from "./BooksTransactionsHistoryItemRow.styles";

interface BooksTransactionsHistoryItemRowProps {
  item: BookTransactionHistoryItem;
  disabled: boolean;
}

const BooksTransactionsHistoryItemRow: FC<
  BooksTransactionsHistoryItemRowProps
> = ({ item, disabled }) => {
  const { t } = useTranslation("common");

  return (
    <Wrapper disabled={disabled}>
      <Hidden xs>
        <TableRow align="center">
          <TableCol xs={12} sm={2} md={2}>
            {item.reference_type ?? "-"}
          </TableCol>
          <TableCol xs={12} sm={5} md={5}>
            {item.description ?? "-"}
          </TableCol>
          <TableCol xs={12} sm={2} md={2}>
            {dayjs(item.created_at).format("YYYY-MM-DD")}
          </TableCol>
          <TableCol xs={12} sm={1} md={1}>
            {item.amount}
          </TableCol>
          <TableCol xs={12} sm={2} md={2}>
            {item.transaction_type}
          </TableCol>
        </TableRow>
      </Hidden>

      <Visible xs>
        <Card>
          <div>
            {t("common.referenceType")}: {item.reference_type ?? "-"}
          </div>
          <CardItem>
            <div>
              <Icon type={IconType.Description} />
            </div>

            <div>{item.description ?? "-"} </div>
          </CardItem>
          <CardFooter>
            <CardFooterItem>
              <Icon type={IconType.Calendar} />
              {dayjs(item.created_at).format("YYYY-MM-DD")}
            </CardFooterItem>
            <div>
              {item.amount} <USD>USD</USD>
            </div>
            <CardFooterItem>
              <Icon type={IconType.Transaction} />
              {item.transaction_type}
            </CardFooterItem>
          </CardFooter>
        </Card>
      </Visible>
    </Wrapper>
  );
};

export default memo(BooksTransactionsHistoryItemRow);
