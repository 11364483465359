import { AxiosError } from "axios";
import { toast } from "react-toastify";

import ToastReportBugButton from "@/containers/UserSnapReportBug/components/ToastReportBugButton";

export const getResponseError = (error: unknown): string | null => {
  const commonErrorMessage = "Something went wrong";

  if (!error) return null;

  if (error instanceof AxiosError && error.response?.data) {
    const { errors, error: responseError } = error.response.data;

    if (Array.isArray(errors)) {
      return errors.join(", ");
    }

    return responseError ?? error.message ?? commonErrorMessage;
  }

  return typeof error === "string" ? error : commonErrorMessage;
};

export const getResponseSuccess = (successMessage: string) => {
  return successMessage ? successMessage : null;
};

export const toastResponseError = (
  response: unknown,
  toastId: string = "toast-response-error",
) => {
  const message = getResponseError(response);

  if (response instanceof AxiosError) {
    toast.error(
      <ToastReportBugButton
        message={message ?? ""}
        response={response}
        toastId={toastId}
      />,
      {
        toastId: toastId,
        theme: "colored",
        autoClose: 20000,
      },
    );
    return;
  }

  toast.error(message, {
    toastId: toastId,
    theme: "colored",
  });
};

export const toastResponseSuccess = (response: string) => {
  const message = getResponseSuccess(response);
  toast.success(message, { theme: "colored" });
};
