import { FC, memo, MouseEvent } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";

import { PlaceholderIcon, Wrapper } from "@/styles/placeholder";

import { ButtonColor, ButtonSize, ButtonVariant } from "@/enums";

import { placeholderIcon } from "../../images";
import { ButtonsWrapper, Message } from "./SearchPlaceholder.styles";

interface SearchPlaceholderProps {
  handleReset?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const SearchPlaceholder: FC<SearchPlaceholderProps> = ({ handleReset }) => {
  const { t } = useTranslation("common");

  return (
    <Wrapper>
      <PlaceholderIcon
        src={placeholderIcon}
        alt={t("parcels.placeholderIcon")}
      />
      <Message>{t("books.search.found")}</Message>
      <ButtonsWrapper>
        {handleReset && (
          <Button
            onClick={handleReset}
            size={ButtonSize.Large}
            color={ButtonColor.Primary}
            variant={ButtonVariant.Filled}
          >
            {t("books.search.tryAnotherSearch")}
          </Button>
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default memo(SearchPlaceholder);
