import { AxiosResponse } from "axios";
import {
  AddInventoryParcelItemsDto,
  AddonsMethodsResponseDto,
  AddParcelsDto,
  CartCommonResponseDto,
  CartResponseDto,
  ConsolidateCartResponseDto,
  ConsolidationAddonsMethodsResponseDto,
  CustomInformationDto,
  DeleteCartResponseDto,
  DeliveryShippingMethodsResponseDto,
  DescriptionsByHsCodes,
  PackingMethodsResponseDto,
  SetAddonsMethodDto,
  SetCartAddressResponseDto,
  SetPackingMethodResponseDto,
  SetPartnerIdResponseDto,
  UpdateCustomsDeclarationDto,
} from "types/api/cart";
import { ImportCustomsDeclarationDto } from "types/api/common";

import axiosInstance from "./axios_instance";

const CartAPI = {
  getCart: async (): Promise<CartResponseDto> => {
    const res = await axiosInstance.get("/current_parcel");
    return res.data;
  },
  getAllDeliveryMethods: async (): Promise<
    DeliveryShippingMethodsResponseDto[]
  > => {
    const res = await axiosInstance.get("/shipping_methods");
    return res.data;
  },
  getDeliveryMethods: async (): Promise<
    DeliveryShippingMethodsResponseDto[]
  > => {
    const res = await axiosInstance.get("/current_parcel/shipping_methods");
    return res.data;
  },
  getPackingMethods: async (): Promise<PackingMethodsResponseDto[]> => {
    const res = await axiosInstance.get("/current_parcel/optional_line_items");
    return res.data;
  },
  getAddonsMethods: async (): Promise<AddonsMethodsResponseDto[]> => {
    const res = await axiosInstance.get("/addons");
    return res.data;
  },
  getConsolidationAddonsMethods: async (): Promise<
    ConsolidationAddonsMethodsResponseDto[]
  > => {
    const res = await axiosInstance.get("/consolidation_addons");
    return res.data;
  },
  deleteCart: (): Promise<DeleteCartResponseDto> =>
    axiosInstance.delete("/current_parcel"),
  addParcels: (data: AddParcelsDto): Promise<AxiosResponse<CartResponseDto>> =>
    axiosInstance.post("current_parcel_shipments", data),
  addInventoryParcelItems: (data: AddInventoryParcelItemsDto) =>
    axiosInstance.post("current_parcel_items", data),
  setAddress: async (
    id: number | string,
  ): Promise<SetCartAddressResponseDto> => {
    const res = await axiosInstance.post(
      "/current_parcel/destination_addresses/select",
      { id },
    );

    return res.data;
  },
  setDeliveryMethod: async (
    carrier: string,
  ): Promise<SetPackingMethodResponseDto> => {
    const res = await axiosInstance.patch(
      "/current_parcel/update_preferred_carrier",
      { preferred_carrier: carrier },
    );

    return res.data;
  },
  setPackingMethods: async (
    codes: string[],
  ): Promise<SetPackingMethodResponseDto> => {
    const res = await axiosInstance.post(
      "/current_parcel/optional_line_items",
      {
        optional_line_item_codes: codes,
      },
    );

    return res.data;
  },

  setAddonsMethods: async (
    data: SetAddonsMethodDto,
  ): Promise<CartCommonResponseDto> => {
    const res = await axiosInstance.patch(
      "/current_parcel_items/update_items_addons",
      data,
    );

    return res.data;
  },
  deleteAddonsMethods: async () => {
    const res = await axiosInstance.delete(
      "/current_parcel_items/delete_all_items_addons",
    );

    return res.data;
  },
  setConsolidationAddonsMethods: async (
    ids: number[],
  ): Promise<CartCommonResponseDto> => {
    const res = await axiosInstance.post("/current_parcel/addons", {
      addons_ids: ids,
    });

    return res.data;
  },
  importCustomInformation: async (): Promise<CartResponseDto> => {
    const res = await axiosInstance.post(
      "/current_parcel/import_customs_informations",
    );

    return res.data;
  },
  createCustomsDeclaration: async ({
    data,
  }: CustomInformationDto): Promise<CartCommonResponseDto> => {
    const res = await axiosInstance.post(
      "/current_parcel/customs_informations",
      { ...data },
    );

    return res.data;
  },
  updateCustomsDeclaration: async (
    data: UpdateCustomsDeclarationDto,
  ): Promise<CartResponseDto> => {
    const { id, ...customDeclaration } = data;
    const res = await axiosInstance.patch(
      `/current_parcel/customs_informations/${id}`,
      { ...customDeclaration.data },
    );

    return res.data;
  },
  deleteCustomsDeclaration: ({ id }: { id: number }) =>
    axiosInstance.delete(`/current_parcel/customs_informations/${id}`),
  importCustomsDeclaration: async ({
    id,
    file,
  }: ImportCustomsDeclarationDto) => {
    const formData = new FormData();
    formData.append("customs_declaration_import[file]", file);

    const res = await axiosInstance.post(
      `/consolidations/${id}/customs_informations/import_declaration`,
      formData,
    );

    return res.data;
  },
  consolidateCart: async (): Promise<ConsolidateCartResponseDto> => {
    const res = await axiosInstance.post("/current_parcel/consolidate");

    return res.data;
  },
  setPartnerId: (id: string | number): Promise<SetPartnerIdResponseDto> =>
    axiosInstance.patch(`/current_parcel/set_post_most_id`, {
      post_most_id: id,
    }),
  getDescriptions: async (): Promise<DescriptionsByHsCodes[]> => {
    const res = await axiosInstance.get("/hs_codes");

    return res.data;
  },
};

export default CartAPI;
