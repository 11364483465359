import ReactDatePicker from "react-datepicker";
import styled from "styled-components";

import { Icon } from "@/components/Icon";
import { Tooltip } from "@/components/Tooltip";

import { Field, Label } from "@/styles/form";
import arrow from "@/icons/arrow.svg";

import { InputIconColor } from "@/enums";

import "react-datepicker/dist/react-datepicker.css";

export const DatePickerLabel = styled(Label)`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker {
    border: none;
    border-radius: 0;
    filter: drop-shadow(0 1px 6px rgba(0, 0, 0, 0.15));
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    border: none;
    height: 13px;
    width: 13px;
    background-color: ${(props) => props.theme.white};
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    -webkit-mask-image: url(${arrow});
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-image: url(${arrow});
    transition: -webkit-mask-image 0.3s ease-in-out;
  }

  .react-datepicker__navigation--next {
    transform: rotate(180deg);
  }

  .react-datepicker__navigation--previous:hover,
  .react-datepicker__navigation--next:hover {
    opacity: 0.75;
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: ${(props) => props.theme.white};
  }

  .react-datepicker__day {
    border-radius: 0 !important;
  }

  .react-datepicker__header {
    background-color: ${(props) => props.theme.primary};
    border-bottom: none;
  }

  .react-datepicker__day--outside-month {
    color: ${(props) => props.theme.black50};
  }

  .react-datepicker__day--in-selecting-range {
    color: ${(props) => props.theme.black50} !important;
    background-color: ${(props) => props.theme.primaryPale} !important;
  }

  .react-datepicker__day--in-range {
    color: ${(props) => props.theme.black};
    background-color: ${(props) => props.theme.primaryPale};
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${(props) => props.theme.primary} !important;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day.react-datepicker__day--selected:hover {
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.primary};
  }

  .react-datepicker__day:hover {
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.primary05};
  }
`;

export const DatePickerField = styled(ReactDatePicker)`
  ${Field}
`;

interface InputIconProps {
  color: InputIconColor;
}

export const InputIcon = styled(Icon)<InputIconProps>`
  background-color: ${({ theme, color }) => theme[color]};
`;

export const IconWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  height: 40px;
  width: 40px;
`;

export const CustomTooltip = styled(Tooltip)`
  .Popover-body {
    max-width: 250px;
    text-align: center;
  }
`;
