import { AxiosResponse } from "axios";
import { DestinationAddressDto } from "types/api/destination_addresses";
import { AddressDto, DestinationsResponseDto } from "types/api/destinations";

import axiosInstance from "./axios_instance";

const DestinationsAPI = {
  getAddresses: async (
    params?: DestinationAddressDto,
  ): Promise<AddressDto[]> => {
    const res = await axiosInstance.get("/destination_addresses", { params });
    return res.data;
  },
  createAddress: (data: {
    destination_address: AddressDto;
  }): Promise<AxiosResponse<DestinationsResponseDto>> =>
    axiosInstance.post("/destination_addresses", data),
  updateAddress: (data: {
    destination_address: AddressDto;
    id: number;
  }): Promise<AxiosResponse<DestinationsResponseDto>> => {
    const { id, ...updateAddressDto } = data;

    return axiosInstance.patch(
      `/destination_addresses/${id}`,
      updateAddressDto,
    );
  },
  deleteAddress: (id: number) =>
    axiosInstance.delete(`/destination_addresses/${id}`),
};

export default DestinationsAPI;
