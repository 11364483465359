import React, { FC, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Checkbox } from "@/components/Checkbox";
import { Tooltip } from "@/components/Tooltip";

import { Currency } from "@/styles/parcels";

import formatter from "@/utils/formatter";
import { useOutgoingUpdateRequireInsurance } from "@/hooks/react-query/outgoing";

import useOutgoingStore from "@/store/useOutgoingStore";
import { useAuthStore } from "@/store";
import { ButtonSize, IconType } from "@/enums";

import {
  DashConnector,
  InsuranceButton,
  InsuranceCheckBoxContainer,
  InsuranceConfirmation,
  InsuranceHelpIcon,
  InsurancePriceWrapper,
  PriceItem,
  PriceWrapper,
  Separator,
  SummaryCurrency,
  SummaryPriceItem,
  SummaryPriceWrapper,
  TooltipInfo,
  TooltipInfoText,
  TotalPriceItem,
  Wrapper,
} from "./PriceList.styles";

interface PriceListProps {
  parcel: any;
}

const PriceList: FC<PriceListProps> = React.memo(({ parcel }) => {
  const { t } = useTranslation("common");
  const {
    userAuth: { display_weight_in: units },
  } = useAuthStore();
  const { isLoadingUpdateInsurance } = useOutgoingStore();

  const { mutateAsync: updateRequireInsurance } =
    useOutgoingUpdateRequireInsurance();
  const deliveryName = parcel.preferred_carrier;
  const deliveryCost = parcel.estimate.price ?? 0;
  const packingCost = parcel.estimate.optional_lines_cost ?? 0;
  const addonsCost = parcel.estimate.items_addons_cost ?? 0;
  const serviceRequestsCost = parcel.estimate.service_requests_cost ?? 0;

  const totalCosts = parcel.estimate.total ?? 0;
  const userBalance = parcel.estimate.user_balance ?? 0;
  const totalToPay = parcel.estimate.user_total ?? 0;
  const state = parcel.state;
  const isPaid = state === "paid" || state === "shipped";

  const userBalanceToShow = Math.min(userBalance, totalCosts);
  const totalToPayToShow = Math.max(totalToPay, 0);

  const hasPackingCosts = packingCost > 0;
  const hasAddonsCosts = addonsCost > 0;
  const hasServiceRequestsCost = serviceRequestsCost > 0;
  const shouldShowUserBalance = userBalance !== 0;

  const weight = parcel.weight && Number(parcel.weight).toFixed(2);
  const isRequireInsurance = parcel.estimate.require_insurance ?? false;
  const [showInsuranceConfirmation, setShowInsuranceConfirmation] =
    useState(false);
  const isEditDisabled = isPaid || showInsuranceConfirmation;

  const onChangeInsurance = () => {
    if (isRequireInsurance === true) {
      updateRequireInsurance({
        id: parcel.id,
        data: {
          require_insurance: false,
        },
      });
    } else {
      setShowInsuranceConfirmation(true);
    }
  };

  const handleInsuranceCancel = useCallback(
    () => setShowInsuranceConfirmation(false),
    [],
  );

  const handleInsuranceConfirm = useCallback(() => {
    updateRequireInsurance({
      id: +parcel.id,
      data: {
        require_insurance: true,
      },
    }).then(() => setShowInsuranceConfirmation(false));
  }, []);

  const renderPriceItem = (title: string, amount: number) => {
    return (
      <PriceItem>
        {title}
        <DashConnector />
        <PriceWrapper>
          <Currency>$</Currency>
          {amount}
        </PriceWrapper>
      </PriceItem>
    );
  };

  const renderSummaryPriceItem = (
    title: string,
    amount: number,
    isNegative?: boolean,
  ) => {
    return (
      <SummaryPriceItem $isHighlighted={!!isNegative}>
        {title}
        <SummaryPriceWrapper $isHighlighted={!!isNegative}>
          <SummaryCurrency>
            {isNegative && amount > 0 ? "-$" : "$"}
          </SummaryCurrency>
          {Math.abs(amount)}
        </SummaryPriceWrapper>
      </SummaryPriceItem>
    );
  };

  return (
    <Wrapper>
      <PriceItem>
        {`${deliveryName} / ${weight} ${t(`units.${units}.primaryShort`)}`}
        <DashConnector />
        <PriceWrapper>
          <Currency>$</Currency>
          {deliveryCost}
        </PriceWrapper>
      </PriceItem>
      <PriceItem>
        <InsuranceCheckBoxContainer>
          <Checkbox
            label={t("parcels.insurance")}
            checked={isRequireInsurance}
            disabled={isEditDisabled}
            isLoading={isLoadingUpdateInsurance}
            onChange={onChangeInsurance}
          />

          <Tooltip
            body={
              <TooltipInfo>
                <TooltipInfoText>
                  {t("parcels.insuranceMessage")}
                </TooltipInfoText>
                <Link to="/" state={{ isOpenInsuranceCollapse: true }}>
                  {t("common.learnMore")}
                </Link>
              </TooltipInfo>
            }
            clickable
          >
            <InsuranceHelpIcon type={IconType.Help} />
          </Tooltip>
        </InsuranceCheckBoxContainer>
        {showInsuranceConfirmation && (
          <InsuranceConfirmation>
            <InsuranceButton
              size={ButtonSize.Small}
              $isConfirm={false}
              onClick={handleInsuranceCancel}
            >
              {t("common.no")}
            </InsuranceButton>
            <InsuranceButton
              size={ButtonSize.Small}
              $isConfirm={true}
              isLoading={isLoadingUpdateInsurance}
              onClick={handleInsuranceConfirm}
            >
              {t("common.yes")}
            </InsuranceButton>
          </InsuranceConfirmation>
        )}
        <InsurancePriceWrapper>
          <Currency>$</Currency>
          {parcel.estimate.insurance ?? 0}
        </InsurancePriceWrapper>
      </PriceItem>
      {hasPackingCosts &&
        renderPriceItem(t("parcels.packingGrade"), packingCost)}
      {hasServiceRequestsCost &&
        renderPriceItem(
          t("parcels.packingServiceRequests"),
          serviceRequestsCost,
        )}
      {hasAddonsCosts &&
        renderPriceItem(t("parcels.packingOptions"), addonsCost)}

      <Separator />

      {shouldShowUserBalance &&
        renderSummaryPriceItem(
          t("parcels.total", { amount: totalCosts }),
          totalCosts,
        )}
      {!isPaid && (
        <>
          {shouldShowUserBalance &&
            renderSummaryPriceItem(
              t("parcels.balance"),
              userBalanceToShow,
              true,
            )}
          <TotalPriceItem>
            {t("parcels.totalToPay")}
            <DashConnector />
            <PriceWrapper>
              <Currency>$</Currency>
              {formatter.price(totalToPayToShow)}
            </PriceWrapper>
          </TotalPriceItem>
        </>
      )}
    </Wrapper>
  );
});

export default PriceList;
