import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

import "@/styles";

import { QueryClientProvider } from "@/providers";
import App from "@/containers/App";

import i18nConfig from "@/translations/i18nConfig";

import { UserSnapReportBugProvider } from "./containers/UserSnapReportBug";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <QueryClientProvider>
    <BrowserRouter>
      <I18nextProvider i18n={i18nConfig}>
        <UserSnapReportBugProvider>
          <App />
        </UserSnapReportBugProvider>
      </I18nextProvider>
    </BrowserRouter>
  </QueryClientProvider>,
);
