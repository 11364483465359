import React, { MouseEvent, useCallback } from "react";

import { useRightPanelStore, useToastStore } from "@/store";
import { IconType } from "@/enums";

import { useUserSnapApi } from "../UserSnapReportBugProvider";
import { ReportBugButton, ReportIcon } from "./ReportBugButton.styles";

const UserSnapReportBugButton = () => {
  const UserSnapApi = useUserSnapApi();
  const { isOpenRightPanel, activeTab } = useRightPanelStore();
  const { isToastWithAxiosError } = useToastStore();

  const handleClickReportBug = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      UserSnapApi?.logEvent("open_report_bug_tool");
    },
    [UserSnapApi],
  );

  return (
    <ReportBugButton
      onClick={(e) => handleClickReportBug(e)}
      isDeclarationsPanel={isOpenRightPanel && activeTab !== 0}
      isToastWithAxiosError={isToastWithAxiosError}
    >
      <ReportIcon type={IconType.BugMonitor} color="white" />
    </ReportBugButton>
  );
};

export default UserSnapReportBugButton;
