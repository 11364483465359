import React, { FC, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { AddButton } from "../Addresses.style";

import {
  ChangingAddressPanelData,
  RightPanelType,
} from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { ButtonColor, ButtonSize, ButtonVariant } from "@/enums";

interface AddAddressButtonProps {
  initialPanelData?: ChangingAddressPanelData;
  returnPreviousPanel?: RightPanelType;
  className?: string;
  isRecipientsPage?: boolean;
}

const AddAddressButton: FC<AddAddressButtonProps> = React.memo(
  ({
    initialPanelData,
    returnPreviousPanel,
    className = "",
    isRecipientsPage = false,
  }) => {
    const { t } = useTranslation("common");
    const [isDisabled, setIsDisabled] = useState(false);

    const { openRightPanel, isOpenRightPanel, panelData } =
      useRightPanelStore();

    const handleClick = useCallback(
      () =>
        openRightPanel(RightPanelType.ADDRESS_FORM, {
          isEditForm: false,
          initialPanelData,
          returnPreviousPanel,
        }),
      [openRightPanel],
    );

    useEffect(() => {
      setIsDisabled(isOpenRightPanel && !panelData?.isEditForm);
    }, [isOpenRightPanel]);

    return (
      <AddButton
        color={ButtonColor.Primary}
        size={ButtonSize.Large}
        variant={isRecipientsPage ? ButtonVariant.Filled : ButtonVariant.Basic}
        onClick={handleClick}
        className={className}
        disabled={isDisabled}
      >
        + {t("common.addAddress", { word: t("common.another") })}
      </AddButton>
    );
  },
);

export default AddAddressButton;
