import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import BooksAPI from "@/api/books_api";

import { BooksTransactionsHistoryParamsDto } from "@/types/api/books";
import { BooksQueryKey as QueryKey } from "@/types";

export function useBooksTransactionsHistory(
  params: BooksTransactionsHistoryParamsDto,
) {
  const fetchBooksTransactionsHistory = useInfiniteQuery({
    queryKey: [QueryKey.BooksTransactionsHistory, params.filter],
    queryFn: ({ pageParam }) =>
      BooksAPI.getBooksTransactionsHistory({
        page: pageParam,
        filter: params.filter,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const totalPages = Math.ceil(
        parseInt(lastPage.headers["total"], 10) /
          parseInt(lastPage.headers["per-page"], 10),
      );
      const nextPage = allPages.length + 1;
      return nextPage <= totalPages ? nextPage : undefined;
    },
  });

  return {
    ...fetchBooksTransactionsHistory,
    data:
      fetchBooksTransactionsHistory.data?.pages
        .map((page) => page.data)
        .flat() ?? [],
  };
}

export function useRechargeBalance() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: BooksAPI.createRechargeBalance,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BooksCreateRechargeBalance],
      });
    },
  });
}
