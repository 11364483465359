import color from "color";

export type VariantStyles = {
  basic: (textColor: string) => string;
  bordered: (borderColor: string) => string;
  filled: (background: string) => string;
};

export const getTextColor = (textColor: string) => `
  color: ${textColor};

  &:hover {
    color: ${color(textColor).alpha(0.5)};
  }

  &:disabled {
    color: ${color(textColor).alpha(0.3)};
  }
`;

export const getBackgroundColor = (background: string) => `
  background-color: ${background};
  color: #fff;
  box-shadow: 0 3px 10px 0 ${color(background).alpha(0.5)};

  &:hover {
    color: #fff;
    background-color: ${color(background).alpha(0.8)};
    box-shadow: 0 1px 6px 0 ${color(background).alpha(0.8)};
  }

  &:disabled {
    background-color: ${color(background).alpha(0.5)};
    box-shadow: 0 1px 8px 0 ${color(background).alpha(0.5)};
  }
`;

export const getBorderColor = (borderColor: string) => `
  color: ${borderColor};
  background-color: #fff;
  border: 1px solid ${borderColor};

  &:hover {
    color: ${color(borderColor).alpha(0.5)};
    border: 1px solid ${color(borderColor).alpha(0.5)};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${color(borderColor).alpha(0.3)};
    border: 1px solid ${color(borderColor).alpha(0.3)};
  }
`;

export const variantStyles: VariantStyles = {
  basic: getTextColor,
  bordered: getBorderColor,
  filled: getBackgroundColor,
};
