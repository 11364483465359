import React, { FC, useCallback } from "react";

import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";

import { ParcelState } from "@/components/ParcelState";
import { ServiceLogo } from "@/components/ServiceLogo";

import { ExcessMarker } from "@/styles/outgoing";
import { ParcelRow } from "@/styles/parcels";

import formatter from "@/utils/formatter";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { OutgoingComponentCommonProps } from "@/types";

import { OutgoingCol, WeightAndPrice } from "./InProgress.styles";

const InProgress: FC<OutgoingComponentCommonProps> = React.memo(
  ({ parcel, units }) => {
    const { t } = useTranslation("common");
    const { isOpenRightPanel, panelData, openRightPanel } =
      useRightPanelStore();

    const deliveryName =
      "preferred_carrier" in parcel ? parcel.preferred_carrier : "";
    const isSelectedShipment =
      isOpenRightPanel && panelData && panelData.detailedItemID === parcel.id;
    const hasExcessItems =
      "excess_items" in parcel && parcel.excess_items.length > 0;
    const clickParcel = useCallback(
      () =>
        openRightPanel(RightPanelType.IN_PROGRESS, {
          detailedItemID: parcel.id,
          excessItems: "excess_items" in parcel ? parcel.excess_items : [],
          returnPreviousPanel: RightPanelType.IN_PROGRESS,
        }),
      [openRightPanel, parcel],
    );

    const state = parcel.state ?? "";

    const weight = () => {
      let weight = formatter.roundTo2Decimal(
        ("total_weight" in parcel && parcel.total_weight) || 0,
      );
      const unit = t(`units.${units}.primaryShort`);

      if (state === "paid") {
        const deliveryCost =
          ("estimate" in parcel && parcel.estimate.price) ?? 0;
        return (
          <WeightAndPrice>{`${weight} ${unit}/$ ${deliveryCost}`}</WeightAndPrice>
        );
      } else {
        return `${weight} ${unit} (${t("parcels.brutto")})`;
      }
    };

    return (
      <ParcelRow selected={isSelectedShipment} onClick={clickParcel}>
        <Hidden xs>
          <OutgoingCol sm={2} xl={2}>
            {parcel.sku}
            {hasExcessItems ? <ExcessMarker /> : null}
          </OutgoingCol>
        </Hidden>
        <Visible xs>
          <OutgoingCol xs={3}>
            {parcel.sku}
            <ParcelState state={state} isShowText={false} />
          </OutgoingCol>
        </Visible>
        <OutgoingCol xs={5} sm={3} xl={2}>
          <ServiceLogo serviceKey={deliveryName} />
          {deliveryName}
        </OutgoingCol>
        <OutgoingCol xs={4} sm={2}>
          {weight()}
        </OutgoingCol>
        <Hidden xs>
          <OutgoingCol sm={2}>
            <ParcelState state={state} />
          </OutgoingCol>
        </Hidden>
      </ParcelRow>
    );
  },
);

export default InProgress;
