import { useEffect, useRef, useState } from "react";

const useSkeletonCalculatedItems = (initialHeight: number, rowHeight = 60) => {
  const [numItems, setNumItems] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateItemsForSkeleton = () => {
    if (containerRef.current) {
      const height = containerRef.current.clientHeight;
      const items = Math.floor((height - initialHeight) / rowHeight);
      setNumItems(items);
    }
  };

  useEffect(() => {
    calculateItemsForSkeleton();
    window.addEventListener("resize", calculateItemsForSkeleton);

    return () =>
      window.removeEventListener("resize", calculateItemsForSkeleton);
  }, [initialHeight]);

  return { numItems, containerRef };
};

export default useSkeletonCalculatedItems;
