import { BookTransactionHistoryItem } from "@/types/api/books";

export const sortFunctionsTransactionHistory = {
  date: (
    a: BookTransactionHistoryItem,
    b: BookTransactionHistoryItem,
    order: string,
  ) =>
    order === "asc"
      ? +new Date(a.created_at) - +new Date(b.created_at)
      : +new Date(b.created_at) - +new Date(a.created_at),
  amount: (
    a: BookTransactionHistoryItem,
    b: BookTransactionHistoryItem,
    order: string,
  ) => (order === "asc" ? +a.amount - +b.amount : +b.amount - +a.amount),
  reference: (
    a: BookTransactionHistoryItem,
    b: BookTransactionHistoryItem,
    order: string,
  ) => {
    const refA = a.reference_type || "-";
    const refB = b.reference_type || "-";
    if (refA === "-") return 1;
    if (refB === "-") return -1;
    return order === "asc"
      ? refA.localeCompare(refB)
      : refB.localeCompare(refA);
  },
  transactionType: (
    a: BookTransactionHistoryItem,
    b: BookTransactionHistoryItem,
    order: string,
  ) => {
    const typeA = a.transaction_type || "-";
    const typeB = b.transaction_type || "-";
    if (typeA === "-") return 1;
    if (typeB === "-") return -1;
    return order === "asc"
      ? typeA.localeCompare(typeB)
      : typeB.localeCompare(typeA);
  },
};
