import React, { FC, FormEvent } from "react";

import { AddressesWrapper, Spinner } from "../Addresses.style";

import { useAddresses } from "@/hooks/react-query/destinations";

import {
  ChangingAddressPanelData,
  RightPanelType,
} from "@/store/useRightPanelStore";
import { useDestinationsStore } from "@/store";

import AddressItem from "./AddressItem";

interface AddressesListProps {
  editable?: boolean;
  deletable?: boolean;
  onSelect?: (id: number | string) => void;
  onSubmit?: (id: number) => Promise<void>;
  selectedAddress?: number | string;
  className?: string;
  returnPreviousPanel?: RightPanelType;
  initialPanelData?: ChangingAddressPanelData;
}

const AddressesList: FC<AddressesListProps> = React.memo(
  ({
    editable = false,
    deletable = false,
    onSelect,
    onSubmit,
    selectedAddress,
    returnPreviousPanel,
    initialPanelData,
    className = "",
  }) => {
    const { isLoading } = useAddresses();
    const { addresses } = useDestinationsStore();

    return (
      <AddressesWrapper className={className}>
        <Spinner isActive={isLoading} />
        {addresses.map((address) => {
          const addressId = address.id;

          return (
            <AddressItem
              onSubmit={onSubmit}
              onSelect={onSelect}
              isSelected={selectedAddress === addressId}
              deletable={deletable}
              editable={editable}
              address={address}
              key={addressId}
              returnPreviousPanel={returnPreviousPanel}
              initialPanelData={initialPanelData}
            />
          );
        })}
      </AddressesWrapper>
    );
  },
);

export default AddressesList;
