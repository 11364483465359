import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  ${({ theme }) => theme.xs`
     margin-bottom: 20px
     flex-direction: column-reverse;
   `}
`;

export const ExactContainer = styled.div`
  ${({ theme }) => theme.xs`
   border-bottom: 1px solid ${theme.lightgrey}};
  `};
`;

export const DatesWrapper = styled.div`
  width: 100%;
  padding: 10px 20px 0 20px;
  display: flex;
  flex-direction: row;
`;

export const ExactTitle = styled.div`
  padding: 15px 20px 0 20px;
  width: 100%;
  color: ${(props) => props.theme.black};
`;

interface DateButtonProps {
  $isSelected: boolean;
}

export const DateButton = styled.button<DateButtonProps>`
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  text-align: left;
  color: ${(props) => props.theme.black};
  border-bottom: 1px solid ${(props) => props.theme.lightgrey};

  &:hover {
    background-color: ${(props) => props.theme.lightgrey03};
  }

  ${({ $isSelected, theme }) =>
    $isSelected && `background-color: ${theme.primaryPale} !important;`}
`;

export const DatesDivider = styled.div`
  min-width: 30px;
  position: relative;

  &::before {
    content: "";
    display: block;
    justify-content: center;
    position: absolute;
    top: calc(50% - 1px);
    left: 10px;
    right: 10px;
    background-color: ${({ theme }) => theme.grey};
    height: 1px;
  }
`;

export const DatesErrorWrapper = styled.div`
  padding: 0 20px 20px 20px;
  margin-top: -10px;
  color: ${({ theme }) => theme.red};
`;
