import dayjs from "dayjs";

import { BooksTransactionsHistoryParamsDto } from "@/types/api/books";
import { BooksTransactionsHistorySearchValues } from "@/types/BooksForms/Search";
import { DateFilterType } from "@/types/common";

const convertingToUTCDate = (date: Date | null) =>
  date &&
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

const getQuickDateSelectionOption = (
  subtractOffsetValue?: number,
  offsetType: "days" | "months" = "days",
) => {
  let date = dayjs().startOf("day");

  if (subtractOffsetValue) {
    date = date.subtract(subtractOffsetValue, offsetType);
  }

  return convertingToUTCDate(date.toDate());
};

const getDateFilter = (value: DateFilterType) => {
  if (!value) return null;

  switch (value) {
    case "today": {
      return { "q[created_at_gteq]": getQuickDateSelectionOption() };
    }
    case "yesterday": {
      return {
        "q[created_at_gteq]": getQuickDateSelectionOption(1),
        "q[created_at_lteq]": getQuickDateSelectionOption(),
      };
    }
    case "lastWeek": {
      return { "q[created_at_gteq]": getQuickDateSelectionOption(7) };
    }
    case "lastMonth": {
      return {
        "q[created_at_gteq]": getQuickDateSelectionOption(1, "months"),
      };
    }
    default:
      return typeof value === "object"
        ? {
            "q[created_at_gteq]": convertingToUTCDate(value.startDate),
            "q[created_at_lteq]": convertingToUTCDate(value.endDate),
          }
        : {};
  }
};

const initialValues = {
  description: "",
  date: "",
  amount: "",
};

export const booksTransactionsHistorySearchFormik = (
  handleSubmit: (values: BooksTransactionsHistoryParamsDto) => void,
) => ({
  initialValues,
  onSubmit: async (values: BooksTransactionsHistorySearchValues) => {
    const { description, date, amount } = values;

    let filter: BooksTransactionsHistoryParamsDto["filter"] | undefined =
      undefined;

    if (description) {
      filter = filter ?? {};
      filter = {
        ...filter,
        "q[description_cont]": description,
      };
    }

    if (amount) {
      filter = filter ?? {};
      filter = {
        ...filter,
        "q[amount_eq]": amount,
      };
    }

    const dateFilter = getDateFilter(date);
    if (dateFilter) {
      filter = filter ?? {};
      filter = {
        ...filter,
        ...dateFilter,
      };
    }

    handleSubmit({
      page: 1,
      filter: filter ?? null,
    });
  },

  displayName: "StorageSearch",
});
