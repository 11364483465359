import React, { FC } from "react";

import { isMobile } from "react-device-detect";

import { breakpoints } from "@/styles";

import SkeletonItem from "./SkeletonItem";
import SkeletonMobileItem from "./SkeletonMobileItem";

interface StorageSkeletonLoaderProps {
  numItems: number;
}

const SkeletonLoader: FC<StorageSkeletonLoaderProps> = ({ numItems }) => {
  const isMobileBreakpoint = +window.innerWidth <= +breakpoints.xs;
  const isMobileDevice = isMobileBreakpoint || isMobile;

  return new Array(numItems)
    .fill(null)
    .map((_, i) =>
      isMobileDevice ? (
        <SkeletonMobileItem key={i} />
      ) : (
        <SkeletonItem key={i} />
      ),
    );
};

export default React.memo(SkeletonLoader);
