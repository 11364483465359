import React, { FC, MouseEvent, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { ServiceLogo } from "@/components/ServiceLogo";

import { OutgoingCol } from "@/styles/outgoing";
import { ParcelButton } from "@/styles/parcels";

import { useOutgoingDetailedItem } from "@/hooks/react-query/outgoing";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { ButtonColor } from "@/enums";
import { OutgoingComponentCommonProps } from "@/types";

import { ParcelWrapper } from "./WaitingFor.styles";

const WaitingForInformation: FC<OutgoingComponentCommonProps> = React.memo(
  ({ parcel, units }) => {
    const { t } = useTranslation("common");
    const { isOpenRightPanel, panelData, openRightPanel } =
      useRightPanelStore();

    const deliveryName =
      "preferred_carrier" in parcel ? parcel.preferred_carrier : "";
    const isSelectedShipment =
      isOpenRightPanel && panelData && panelData.detailedItemID === parcel.id;
    const isEditCustomData =
      "is_customs_data_invalid" in parcel
        ? !!parcel.is_customs_data_invalid
        : false;
    const isProhibitedGoods =
      "is_prohibited_goods" in parcel ? !!parcel.is_prohibited_goods : false;
    const isPassportInvalid =
      "is_passport_invalid" in parcel ? !!parcel.is_passport_invalid : false;
    const depth = ("depth" in parcel && parcel.depth) || 0;
    const height = ("height" in parcel && parcel.height) || 0;
    const width = ("width" in parcel && parcel.width) || 0;
    const weight =
      "total_weight" in parcel ? Number(parcel.total_weight).toFixed(2) : 0;
    const openMoreInfo = useCallback(
      (event: MouseEvent, openTab?: string) => {
        event.stopPropagation();
        openRightPanel(RightPanelType.WAITING_PAYMENT, {
          detailedItemID: parcel.id,
          excessItems: "excess_items" in parcel ? parcel.excess_items : [],
          openTab: openTab,
          isEditCustomData,
        });
      },
      [openRightPanel, parcel, isEditCustomData],
    );

    const clickAddIdInformation = useCallback(
      (id: number) => (event: MouseEvent) => {
        event.stopPropagation();

        useOutgoingDetailedItem(id);
        openRightPanel(RightPanelType.ADDRESS_FORM, {
          isEditForm: true,
          detailedItemID: id,
          openTab: "Custom ID",
          addressId: id,
          isOutgoingUpdateAddress: true,
        });
      },
      [openRightPanel],
    );

    const clickProhibitedGoodsFound = useCallback(
      (event: MouseEvent) => {
        event.stopPropagation();
        openRightPanel(RightPanelType.PROHIBITED_FOUND);
      },
      [openRightPanel],
    );

    return (
      <ParcelWrapper
        selected={isSelectedShipment}
        onClick={(event) => openMoreInfo(event)}
      >
        <OutgoingCol xs={2} lg={1} xl={2}>
          {parcel.sku}
        </OutgoingCol>
        <OutgoingCol xs={6} sm={3} xl={2}>
          <ServiceLogo serviceKey={deliveryName} />
          {deliveryName}
        </OutgoingCol>
        <OutgoingCol xs={4} sm={3} lg={2}>
          {width}
          <span>x</span>
          {height}
          <span>x</span>
          {depth} {t(`units.${units}.secondaryShort`)}
        </OutgoingCol>
        <OutgoingCol xs={2} sm={1}>
          {`${weight} ${t(`units.${units}.primaryShort`)}`}
        </OutgoingCol>
        <OutgoingCol xs={10} sm={3} md={4} lg={5} xl={7}>
          {isPassportInvalid && (
            <ParcelButton
              color={ButtonColor.Primary}
              onClick={clickAddIdInformation(parcel.id)}
            >
              {t("common.addIdInformation")}
            </ParcelButton>
          )}
          {isProhibitedGoods && (
            <ParcelButton
              color={ButtonColor.Primary}
              onClick={clickProhibitedGoodsFound}
            >
              {t("common.prohibitedGoodsFound")}
            </ParcelButton>
          )}
          {isEditCustomData && (
            <ParcelButton
              color={ButtonColor.Primary}
              onClick={(event: MouseEvent<HTMLButtonElement>) =>
                openMoreInfo(event, "Customs data")
              }
            >
              {t("common.editCustomsData")}
            </ParcelButton>
          )}
        </OutgoingCol>
      </ParcelWrapper>
    );
  },
);

export default WaitingForInformation;
