import { FC, memo } from "react";

import { ToastContainer as Toast, ToastContainerProps } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { ToastBody } from "./ToastContainer.styles";

const ToastContainer: FC<ToastContainerProps> = (props) => {
  return (
    <ToastBody>
      <Toast
        position="bottom-center"
        closeButton={false}
        hideProgressBar
        autoClose={4000}
        pauseOnFocusLoss={false}
        draggable={false}
        style={{
          position: "fixed",
          zIndex: 99999,
        }}
        {...props}
      />
    </ToastBody>
  );
};

export default memo(ToastContainer);
