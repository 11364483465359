import React, { FC, memo, useEffect, useState } from "react";

import { Hidden } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { Waypoint } from "react-waypoint";

import { Spinner } from "@/containers/Storage/components/StorageList/StorageList.styles";

import { BookTransactionHistoryItem } from "@/types/api/books";

import { BooksRecipientListWrapper as BooksRecipientHistoryListWrapper } from "../BooksRecipientsList/BooksRecipientsList.styles";
import { BooksTransactionsHistoryItemRow } from "./BooksTransactionsHistoryItemRow";
import {
  ScrollableTableBody,
  StickyTableRow,
  TableCol,
  TransactionsTableHeading,
} from "./BooksTransactionsHistoryItemRow/BooksTransactionsHistoryItemRow.styles";
import { sortFunctionsTransactionHistory } from "./helper";

interface BooksTransactionsHistoryListProps {
  historyItems: BookTransactionHistoryItem[];
  disabled: boolean;
  onFetchNextPage: () => void;
  hasMore: boolean;
}

const BooksTransactionsHistoryList: FC<BooksTransactionsHistoryListProps> = ({
  historyItems,
  disabled,
  onFetchNextPage,
  hasMore,
}) => {
  const { t } = useTranslation("common");
  const [sortState, setSortState] = useState({
    date: "desc",
    amount: "default",
    reference: "default",
    transactionType: "default",
  });

  const [sortedHistoryItems, setSortedHistoryItems] =
    useState<BookTransactionHistoryItem[]>(historyItems);

  useEffect(() => {
    if (!historyItems) return;
    let sortedData = [...historyItems];

    for (const [key, value] of Object.entries(sortState)) {
      if (value !== "default") {
        sortedData.sort((a, b) =>
          sortFunctionsTransactionHistory[
            key as keyof typeof sortFunctionsTransactionHistory
          ](a, b, value),
        );
      }
    }

    setSortedHistoryItems(sortedData);
  }, [sortState, historyItems]);

  const handleSortClick = (type: keyof typeof sortState) => {
    setSortState((prevState) => {
      const newState = {
        date: "default",
        amount: "default",
        reference: "default",
        transactionType: "default",
      };

      if (type === "date") {
        newState.date = prevState.date === "desc" ? "asc" : "desc";
      } else if (type === "amount") {
        newState[type] =
          prevState[type] === "desc"
            ? "asc"
            : prevState[type] === "asc"
              ? "default"
              : "desc";
        newState.date = "desc";
      } else {
        newState[type] =
          prevState[type] === "asc"
            ? "desc"
            : prevState[type] === "desc"
              ? "default"
              : "asc";
        newState.date = "desc";
      }
      return newState;
    });
  };

  const renderSortIndicator = (type: keyof typeof sortState) => {
    return sortState[type] === "asc"
      ? "↑"
      : sortState[type] === "desc"
        ? "↓"
        : "";
  };

  return (
    <BooksRecipientHistoryListWrapper>
      <Hidden xs>
        <TransactionsTableHeading>
          <StickyTableRow align="center">
            <TableCol
              xs={12}
              sm={2}
              md={2}
              onClick={() => handleSortClick("reference")}
            >
              {t("common.referenceType")} {renderSortIndicator("reference")}
            </TableCol>
            <TableCol xs={12} sm={5} md={5}>
              {t("common.description")}
            </TableCol>
            <TableCol
              xs={12}
              sm={2}
              md={2}
              onClick={() => handleSortClick("date")}
            >
              {t("common.date")} {renderSortIndicator("date")}
            </TableCol>
            <TableCol
              xs={12}
              sm={1}
              md={1}
              onClick={() => handleSortClick("amount")}
            >
              {t("common.amount")} {renderSortIndicator("amount")}
            </TableCol>
            <TableCol
              xs={12}
              sm={2}
              md={2}
              onClick={() => handleSortClick("transactionType")}
            >
              {t("common.transactionType")}{" "}
              {renderSortIndicator("transactionType")}
            </TableCol>
          </StickyTableRow>
        </TransactionsTableHeading>
      </Hidden>

      <ScrollableTableBody>
        {sortedHistoryItems.map((item) => {
          return (
            <BooksTransactionsHistoryItemRow
              item={item}
              key={item.id}
              disabled={disabled}
            />
          );
        })}
        {hasMore && (
          <>
            <Waypoint onEnter={onFetchNextPage} />
            <Spinner key={0} isActive />
          </>
        )}
      </ScrollableTableBody>
    </BooksRecipientHistoryListWrapper>
  );
};

export default memo(BooksTransactionsHistoryList);
