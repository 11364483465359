import React, { FC } from "react";

import ContentLoader from "react-content-loader";
import { isMobile } from "react-device-detect";

import { breakpoints, colors } from "@/styles";

import SkeletonItem from "./SkeletonItem";
import SkeletonMobileItem from "./SkeletonMobileItem";

interface StorageSkeletonLoaderProps {
  numItems: number;
}

const SkeletonLoader: FC<StorageSkeletonLoaderProps> = ({ numItems }) => {
  const isMobileBreakpoint = +window.innerWidth <= +breakpoints.xs;
  const isMobileDevice = isMobileBreakpoint || isMobile;

  return (
    <>
      <ContentLoader
        speed={2}
        width="100%"
        height={100}
        backgroundColor={colors.skeletonBackgroundColor}
        foregroundColor={colors.skeletonForegroundColor}
      >
        <rect x="0" y="6" width="100%" height="25" rx={5} ry={5} />
        <rect x="0" y="45" width="15%" height="25" rx={5} ry={5} />
      </ContentLoader>
      {new Array(numItems)
        .fill(null)
        .map((_, i) =>
          isMobileDevice ? (
            <SkeletonMobileItem key={i} />
          ) : (
            <SkeletonItem key={i} />
          ),
        )}
    </>
  );
};

export default React.memo(SkeletonLoader);
