import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { ImageViewer2 } from "@/components/ImageViewer2";

import { Message, MessageIcon } from "@/styles/serviceRequest";

import { IconType } from "@/enums";

import { cancelableStates, requestNames } from "../ServiceRequests/helpers";
import {
  IconWrapper,
  MessageBlock,
  ProcessIcon,
  UserComment,
  VideoPlayer,
  WarehouseComment,
  WarehouseCommentWrapper,
} from "./RequestInfo.styles";

requestNames;

interface RequestInfoProps {
  request: any;
}

const RequestInfo: FC<RequestInfoProps> = ({ request }) => {
  const { t } = useTranslation("common");
  const requestType =
    requestNames[
      request.service_request_type.type.toLowerCase() as keyof typeof requestNames
    ];

  const userComment = request.user_comment;
  const video = request.vimeo_id;
  const pictures = request.pictures ?? [];
  const warehouseComment =
    request.additional_photos_warehouse_comment ||
    request.power_on_test_warehouse_comment ||
    request.custom_warehouse_comment;

  const renderPendingTranslation = () => (
    <MessageBlock>
      <IconWrapper>
        <ProcessIcon type={IconType.Process} />
        <MessageIcon type={requestType as IconType} />
      </IconWrapper>
      <Message>{t(`serviceRequests.${requestType}.unprocessed`)}</Message>
      {requestType !== "requestInventoryShipment" && (
        <Message>{t("serviceRequests.processTime")}</Message>
      )}
    </MessageBlock>
  );
  const renderRequested = () => (
    <>
      {warehouseComment && (
        <WarehouseCommentWrapper>
          <b>{t("parcels.warehouseComment")}:</b>
          <WarehouseComment>{warehouseComment}</WarehouseComment>
        </WarehouseCommentWrapper>
      )}
      {userComment && (
        <UserComment>
          <b>{t("common.comment")}: </b>
          {userComment}
        </UserComment>
      )}
      {video && <VideoPlayer url={`https://vimeo.com/${video}`} />}
      {!!pictures.length && <ImageViewer2 images={pictures} />}
    </>
  );

  return (
    <>
      {cancelableStates.includes(request.state) && renderPendingTranslation()}
      {request.state === "requested" && renderRequested()}
    </>
  );
};

export default React.memo(RequestInfo);
