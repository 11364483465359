import { Container } from "react-grid-system";
import styled from "styled-components";

import { PageWrapper } from "@/layouts/Layout.styles";

import {
  fadeInLeft,
  fadeOutRight,
  ParcelCol,
  ParcelRow,
} from "@/styles/parcels";

interface RecipientsHeadingProps {
  $isPanelOpened: boolean;
}

interface WrapperRecipientItemRowProps {
  disabled: boolean;
}

export const Wrapper = styled(PageWrapper)`
  position: absolute;
  width: 100%;
  animation: none;

  &.page-enter {
    animation: ${fadeInLeft} 0.22s ease;
  }

  &.page-exit {
    animation: ${fadeOutRight} 0.22s ease;
  }
`;

export const RecipientsHeading = styled.div<RecipientsHeadingProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  gap: 20px;
  transition: padding 0.2s ease-in-out;

  ${({ theme, $isPanelOpened }) =>
    $isPanelOpened &&
    theme.range(
      "padding-right",
      "370px",
      "410px",
      `${theme.breakpoints.lg}px`,
      `${theme.breakpoints.xl}px`,
    )}
`;

export const AddAddressBlock = styled.div`
  text-wrap: nowrap;
  overflow-wrap: nowrap;
`;

export const StorageHeading = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const PageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const WrapperRecipientItemRow = styled.div<WrapperRecipientItemRowProps>`
  &:not(:first-child) > div {
    border-top: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const TableCol = styled(ParcelCol)`
  padding: 10px 0px;
  text-align: left;
  line-height: 20px;

  max-width: 100%;

  overflow-wrap: break-word;

  .tooltip-content-checkbox {
    min-width: 18px;
  }

  &:nth-child(4) > div {
    margin-left: 15px;
  }

  ${(props) => props.theme.xs`
  margin-bottom: 0px;
`}
`;

interface TableRowProps {
  selected?: boolean;
}

export const TableRow = styled(ParcelRow)<TableRowProps>`
  ${(props) => props.theme.sm`
    height: 75px;
    padding: 5px 0 0;
    font-size: 13px;
  `}

  ${(props) => props.theme.xs`
    height: 75px;
    font-size: 12px;
    padding: 5px 0 0;
  `};
`;

export const Message = styled.p`
  margin: 17px 0;
  font-size: 26px;
  font-weight: bold;
  color: ${(props) => props.theme.black};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    margin: 5px 10px;
  }
`;

export const StyledGrid = styled(Container)`
  position: relative;
`;
