import { FC, useState } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { SearchInput } from "@/components/SearchInput";

import { SearchWrapper } from "../Addresses.style";

import { useAddresses } from "@/hooks/react-query/destinations";

export interface AddressSearchValues {
  address: string;
}
interface SearchAddressDto {
  search_in_address: string;
}

const AddressSearch: FC = () => {
  const { t } = useTranslation("common");
  const [searchAddressDto, setSearchAddressDto] = useState<
    Partial<SearchAddressDto>
  >({});
  useAddresses(searchAddressDto, {
    enabled: false,
  });

  const { values, handleSubmit, setFieldValue, initialValues } =
    useFormik<AddressSearchValues>({
      initialValues: {
        address: "",
      },
      onSubmit: (values) => {
        setSearchAddressDto(
          values.address
            ? {
                search_in_address: values.address,
              }
            : {},
        );
      },
    });

  return (
    <SearchWrapper>
      <SearchInput<AddressSearchValues>
        name="address"
        value={values.address}
        placeholder={t("auth.startTypingAddress")}
        initialValue={initialValues.address}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
      />
    </SearchWrapper>
  );
};

export default AddressSearch;
