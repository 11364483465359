import { SetPackingMethodDto } from "./cart";
import { AddressDto } from "./destinations";

export type OutgoingDto = {
  type: OutgoingType;
  params: {
    page: number;
    per_page: number;
  };
};

export type RemoveItemFromConsolidationDto = {
  consolidationId: number;
  id: number;
  isCartVariant: boolean;
};

export interface OutgoingResponseDto {
  total_weight: number;
  weight: number;
  height: number;
  depth: number;
  width: number;
  id: number;
  sku: string;
  preferred_carrier: string;
  state: string;
  package_count: number;
  estimate: Estimate;
  excess_items: any[];
  created_at: string;
  delivery?: string;
}

export interface OutgoingUpdateAddressDto {
  id: number;
  destination_address: AddressDto;
}
export interface UpdateConsolidationAddonsDto {
  id: number;
  data: {
    addons_ids: number[];
  };
}

export interface UpdateConsolidationOptionalLineItemsDto {
  id: number;
  data: SetPackingMethodDto;
}

export enum OutgoingType {
  WaitingForInformation = "waiting_for_information",
  WaitingForPayment = "waiting_for_payment",
  PackingInProgress = "packing_in_progress",
  Paid = "paid",
}

export interface Estimate {
  price: string;
  insurance: string;
  insurance_percentage: string;
  optional_lines_cost: string | number;
  items_addons_cost: string | number;
  service_requests_cost: string | number;
  consolidation_addons_cost: string | number;
  total: number;
  require_insurance: boolean;
  consolidation_id: number;
  user_balance: number;
  user_total: number;
}

interface ConsolidationAddon {
  id: number;
  code: string;
  description: string;
  description_ru: string;
  description_en: string;
  price: string;
  icon_url: string;
}

export interface Picture {
  id: number;
  large: string;
  medium: string;
}

export interface OutgoingItem {
  id: number;
  created_at: string;
  status: string;
  sku: string;
  pack_status: string;
  quantity: number;
  customs_declarations: any[];
  declared_value: number;
  shipment_tracking_number: string;
  shipment_id: number;
  addons: (string | number)[];
  emblem_thumb_url: string;
  pictures: Picture[];
  service_requests: any[];
  package: any;
  weight?: number;
  description?: string;
  customer_comment?: string;
}

export interface OutgoingDetailedItemResponseDto {
  total_weight: number;
  weight: number;
  height: number;
  depth: number;
  width: number;
  id: number;
  created_at: string;
  consolidated_on: string;
  state: string;
  sku: string;
  address_1: string;
  tax_id: string;
  city: string;
  country: string;
  street: string;
  house: string;
  hull: string;
  flat: string;
  destination_address_id: number;
  address_spent_in_month: number;
  postal_code: string;
  phone: string;
  first_name: string;
  last_name: string;
  content_type: string;
  requested_at: string;
  preferred_carrier: string;
  estimate: Estimate;
  consolidation_addons: ConsolidationAddon[];
  optional_line_item_codes: string[];
  packages: any[];
  items: OutgoingItem[];
  pictures: Picture[];
  addons: any[];
}

interface ConsolidationAddon {
  id: number;
  code: string;
  description: string;
  description_ru: string;
  description_en: string;
  price: string;
  icon_url: string;
}

interface Consolidation {
  total_weight: number;
  weight: number;
  height: number;
  depth: number;
  width: number;
  id: number;
  created_at: string;
  consolidated_on: string;
  state: string;
  sku: string;
  address_1: string;
  tax_id: string;
  city: string;
  country: string;
  street: string;
  house: string;
  hull: string;
  flat: string;
  address_spent_in_month: number;
  postal_code: string;
  phone: string;
  first_name: string;
  last_name: string;
  content_type: string;
  requested_at: string;
  preferred_carrier: string;
  estimate: Estimate;
  consolidation_addons: ConsolidationAddon[];
  optional_line_item_codes: string[];
  packages: any[];
  items: Item[];
  pictures: Picture[];
}

interface Item {
  id: number;
  created_at: string;
  status: string;
  pack_status: string;
  quantity: number;
  customs_declarations: any[];
  declared_value: number;
  shipment_tracking_number: string;
  shipment_id: number;
  addons: any[];
  emblem_thumb_url: string;
  pictures: Picture[];
  service_requests: any[];
  weight: number;
  sku: string;
  item_type: number;
  problem: boolean;
  consolidation: {
    sku: string;
  };
}

interface MainPicture {
  large: string;
  medium: string;
  origin: string;
  thumb: string;
  tag: number;
}

export interface OutgoingShipmentResponseDto {
  weight: number;
  id: number;
  description: string;
  received_at: string;
  emblem_thumb_url: string;
  service_requests_count: any[];
  addons: any[];
  vendor: string;
  package_type: string;
  consolidation: Consolidation;
  items: Item[];
  pictures: MainPicture[];
  service_request_addons: any[];
  service_requests: any[];
}

export interface CancelConsolidationResponseDto {
  consolidation_id: number;
  status: string;
  items: any[];
}

export interface UpdateConsolidationAddonsResponseDto {
  estimate: Estimate;
  addons_codes: string[];
  id: number;
}

export type UpdateRequireInsuranceResponseDto = Estimate;
