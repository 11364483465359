import { FC, memo } from "react";

import { useTranslation } from "react-i18next";

import { ButtonColor } from "@/enums";

import { UndoButton, UndoText, UndoWrapper } from "./Undo.styles";

interface UndoProps {
  text: string;
  onClick: () => void;
  className?: string;
}

const Undo: FC<UndoProps> = ({ text, onClick, className = "" }) => {
  const { t } = useTranslation("common");

  return (
    <UndoWrapper className={className}>
      <UndoText>{text}</UndoText>
      <UndoButton color={ButtonColor.Primary} onClick={onClick}>
        {t("common.undo")}
      </UndoButton>
    </UndoWrapper>
  );
};

export default memo(Undo);
