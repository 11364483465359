import { AxiosResponse } from "axios";
import { CustomsDeclaration as CustomDeclarationResponseDto } from "types/api/common";
import { AddressDto } from "types/api/destinations";
import {
  ShipmentsUpdateCommentDto,
  UpdateCommentResponseDto,
} from "types/api/shipments";

import {
  CustomInformationDto,
  DeliveryShippingMethodsResponseDto,
  SetDeliveryMethodDto,
  UpdateRequireInsuranceDto,
} from "./../types/api/cart";
import {
  CancelConsolidationResponseDto,
  OutgoingDetailedItemResponseDto,
  OutgoingDto,
  OutgoingResponseDto,
  OutgoingShipmentResponseDto,
  OutgoingUpdateAddressDto,
  RemoveItemFromConsolidationDto,
  UpdateConsolidationAddonsDto,
  UpdateConsolidationAddonsResponseDto,
  UpdateConsolidationOptionalLineItemsDto,
  UpdateRequireInsuranceResponseDto,
} from "./../types/api/outgoing";
import axiosInstance from "./axios_instance";

export const OutgoingAPI = {
  getOutgoing: (
    outgoingDto: OutgoingDto,
  ): Promise<AxiosResponse<OutgoingResponseDto[]>> => {
    const { type, params } = outgoingDto;

    return axiosInstance.get(`/outgoing/consolidations/${type}`, {
      params,
    });
  },
  getDetailedItem: async (
    id: number,
  ): Promise<OutgoingDetailedItemResponseDto> => {
    const res = await axiosInstance.get(`/consolidations/${id}`);

    return res.data;
  },
  getShipment: async (id: number): Promise<OutgoingShipmentResponseDto> => {
    const res = await axiosInstance.get(`/shipments/${id}`);

    return res.data;
  },
  updateComment: async (
    updateCommentDto: ShipmentsUpdateCommentDto,
  ): Promise<UpdateCommentResponseDto> => {
    const { id, data } = updateCommentDto;
    const res = await axiosInstance.patch(`/shipments/${id}`, data);

    return res.data;
  },
  getCustomsDeclarations: async (
    id: number,
  ): Promise<CustomDeclarationResponseDto[]> => {
    const res = await axiosInstance.get(
      `/consolidations/${id}/customs_informations`,
    );

    return res.data;
  },
  createCustomsDeclaration: async ({
    itemId,
    data,
  }: {
    itemId: number;
    data: CustomInformationDto;
  }): Promise<CustomDeclarationResponseDto> => {
    const res = await axiosInstance.post(
      `/consolidations/${itemId}/customs_informations`,
      data,
    );

    return res.data;
  },
  updateCustomsDeclaration: ({
    itemId,
    id,
    data,
  }: {
    itemId: number;
    id: number;
    data: CustomInformationDto;
  }): Promise<AxiosResponse<CustomDeclarationResponseDto>> =>
    axiosInstance.patch(
      `/consolidations/${itemId}/customs_informations/${id}`,
      data,
    ),
  deleteCustomsDeclaration: ({
    id,
    itemId,
  }: {
    itemId: number;
    id: number;
  }): Promise<AxiosResponse<CustomDeclarationResponseDto[]>> =>
    axiosInstance.delete(
      `/consolidations/${itemId}/customs_informations/${id}`,
    ),
  updateDeliveryMethod: ({
    id,
    data,
  }: SetDeliveryMethodDto): Promise<AxiosResponse<OutgoingResponseDto>> =>
    axiosInstance.patch(`/consolidations/${id}/update_preferred_carrier`, data),
  updateAddress: ({
    id,
    destination_address,
  }: OutgoingUpdateAddressDto): Promise<AxiosResponse<AddressDto>> =>
    axiosInstance.patch(`/consolidations/${id}/update_address`, {
      destination_address: { ...destination_address },
    }),
  cancelConsolidation: (
    id: number,
  ): Promise<AxiosResponse<CancelConsolidationResponseDto>> =>
    axiosInstance.post(`/consolidations/${id}/cancel`),
  updateRequireInsurance: ({
    id,
    data,
  }: UpdateRequireInsuranceDto): Promise<
    AxiosResponse<UpdateRequireInsuranceResponseDto>
  > =>
    axiosInstance.patch(`/consolidations/${id}/update_require_insurance`, data),
  getDeliveryMethods: async (
    id: number,
  ): Promise<DeliveryShippingMethodsResponseDto[]> => {
    const res = await axiosInstance.get(
      `/consolidations/${id}/shipping_methods`,
    );

    return res.data;
  },
  updateConsolidationAddons: ({
    id,
    data,
  }: UpdateConsolidationAddonsDto): Promise<
    AxiosResponse<UpdateConsolidationAddonsResponseDto>
  > => axiosInstance.post(`/consolidations/${id}/addons`, data),
  updateConsolidationOptionalLineItems: ({
    id,
    data,
  }: UpdateConsolidationOptionalLineItemsDto): Promise<
    AxiosResponse<string[]>
  > => axiosInstance.post(`/consolidations/${id}/optional_line_items`, data),

  removeItemFromConsolidation: ({
    consolidationId,
    id,
  }: RemoveItemFromConsolidationDto): Promise<AxiosResponse<unknown>> =>
    axiosInstance.delete(`/consolidations/${consolidationId}/items/${id}`),
};

export default OutgoingAPI;
