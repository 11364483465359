import color from "color";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import { StyledScrollBars } from "@/components/ScrollableContent/ScrollableContent.styles";

interface WrapperProps {
  $isOpen: boolean;
  $isNativeBrowserNavSwipe: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  user-select: text;
  z-index: 3;
  position: fixed;
  max-width: 420px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  right: ${({ $isOpen }) => ($isOpen ? "0" : "-440px")};
  box-shadow: -10px 0 20px 0 rgba(0, 0, 0, 0.05);
  transition: ${({ $isNativeBrowserNavSwipe }) =>
    $isNativeBrowserNavSwipe ? "none" : "right 0.2s ease-out"};

  ${({ $isOpen, theme }) => theme.xs`
    max-width: 100vw;
    box-shadow: none;
    right: ${$isOpen ? "0" : "-100vw"};
  `};
`;

export const Container = styled.aside`
  height: 100%;
  position: relative;
`;

export const LeftArrowIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  background-color: #afb0b3;
  margin-right: 6px;
  pointer-events: none;

  &&& {
    transition: opacity 0.2s;
  }
`;

export const BackButton = styled(Button)`
  font-size: 18px;
  display: flex;
  padding: 0;
  align-items: center;
  margin-right: 6px;

  &:hover {
    ${LeftArrowIcon} {
      opacity: 0.5;
    }
  }
`;

export const BackArrow = styled(Icon)`
  width: 10px;
  height: 16px;
  margin-right: 12px;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2;

  .icon {
    transition: background-color 0.4s;
  }

  &:hover > .icon {
    background-color: ${({ theme }) => color(theme.grey).darken(0.2).hex()};
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  max-height: 100%;
`;

interface HeadingProps {
  $isBordered?: boolean;
}

export const Heading = styled.div<HeadingProps>`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 18px;
  font-weight: normal;

  ${({ theme }) =>
    theme.range(
      "padding",
      "15px",
      "22px",
      `${theme.breakpoints.lg}px`,
      `${theme.breakpoints.xl}px`,
    )};

  ${({ $isBordered, theme }) =>
    $isBordered && `border-bottom: 1px solid ${theme.black05}`};
`;

export const HeadingIcon = styled(Icon)`
  transition: background-color 0.3s;
  background-color: ${({ theme }) => theme.grey};

  &:hover {
    background-color: ${({ theme }) => color(theme.grey).darken(0.2).hex()};
  }
`;

interface ContentProps {
  disabled?: boolean;
  className?: string;
}

export const Content = styled(StyledScrollBars)<ContentProps>`
  animation: 0.3s ${keyframes`${fadeIn}`};

  & > div:first-child {
    padding-bottom: 15px !important;
    padding: 15px;
  }

  ${(props) =>
    props.disabled &&
    `
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-wrap: wrap-reverse;
  justify-content: center;

  ${(props) => props.theme.xs`
    padding: 15px 0;
  `}

  button {
    margin: 5px;
  }
`;
