import { create } from "zustand";

interface UseBooksStore {
  isToastWithAxiosError: boolean;
  updateIsToastWithAxiosError: (bool: boolean) => void;
}

const initialState = {
  isToastWithAxiosError: false,
};

const useToastStore = create<UseBooksStore>((set) => ({
  ...initialState,

  updateIsToastWithAxiosError: (bool) =>
    set(() => ({
      isToastWithAxiosError: bool,
    })),
}));

export default useToastStore;
