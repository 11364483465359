import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { useTranslation } from "react-i18next";

import {
  AddressButton,
  AddressButtons,
  AddressItemWrapper,
  AddressText,
  AddressTitle,
  AddressVerifyIcon,
  UndoWrapper,
} from "../Addresses.style";

import { useDeleteAddress } from "@/hooks/react-query/destinations";
import { useDeletedItems } from "@/hooks";

import { HistoryFullDetailed } from "@/store/useHistoryStore";
import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { ButtonColor, IconType } from "@/enums";
import { AddressDto } from "@/types/api/destinations";

interface AddressItemProps<T> {
  returnPreviousPanel?: RightPanelType;
  isHideButtons?: boolean;
  deletable?: boolean;
  editable?: boolean;
  isSelected?: boolean;
  onSubmit?: (id: number) => Promise<void>;
  onSelect?: (id: number) => void;
  className?: string;
  address: T;
  initialPanelData?: object;
}

const AddressItem = React.memo(
  <T extends AddressDto | HistoryFullDetailed>({
    onSelect,
    address,
    isHideButtons = false,
    deletable = false,
    editable = false,
    isSelected = false,
    returnPreviousPanel,
    initialPanelData,
    onSubmit,
    className = "",
  }: AddressItemProps<T>) => {
    const addressID = address && address.id;
    const { mutateAsync: deleteAddress } = useDeleteAddress();
    const [deletedItems, handleRemove, handleUndo] =
      useDeletedItems(deleteAddress);
    const { openRightPanel } = useRightPanelStore();
    const { t } = useTranslation("common");
    const mainRef = useRef<HTMLDivElement>(null);

    const preventDoubleClickTextSelect = (event: MouseEvent) => {
      if (event.detail > 1) {
        event.preventDefault();
        handleDoubleClick();
      }
    };

    useEffect(() => {
      if (mainRef.current) {
        mainRef.current.addEventListener(
          "mousedown",
          preventDoubleClickTextSelect as unknown as EventListenerOrEventListenerObject,
          false,
        );
      }

      return () => {
        if (mainRef.current) {
          mainRef.current.removeEventListener(
            "mousedown",
            preventDoubleClickTextSelect as unknown as EventListenerOrEventListenerObject,
          );
        }
      };
    }, []);

    const editAddress = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        openRightPanel(RightPanelType.ADDRESS_FORM, {
          address,
          isEditForm: true,
          initialPanelData,
          returnPreviousPanel,
          addressId: addressID || address?.destination_address_id,
        });
      },
      [address, openRightPanel, addressID],
    );

    const handleSelect = useCallback(
      () => onSelect && addressID && onSelect(addressID),
      [onSelect, addressID],
    );

    const handleDoubleClick = useCallback(
      () => onSubmit && addressID && onSubmit(addressID),
      [onSubmit, addressID],
    );

    const fullName = useMemo(
      () => `${address?.first_name} ${address?.last_name}`,
      [address],
    );
    const streetPart = useMemo(() => {
      if (address?.street) {
        return [`${address?.street}`];
      } else {
        return [
          `${address?.address_1}`,
          `${"address_2" in address ? `\n${address.address_2}` : ""}`,
        ];
      }
    }, [address]);
    const addressText = useMemo(
      () =>
        [
          ...streetPart,
          address.house && `${t("abbreviations.house")} ${address.house}`,
          address.hull && `${t("abbreviations.hull")} ${address.hull}`,
          address.flat && `${t("abbreviations.appartement")} ${address.flat}`,
          `\n${address.city}`,
          "zip_code" in address ? address.zip_code : address.postal_code,
          address.country,
          `\n${address.phone}`,
        ]
          .filter((item) => item)
          .join(", "),
      [address, t],
    );
    const isPassportValid = useMemo(
      () =>
        !!(
          address.passport_full_name &&
          address.passport_issued_by &&
          address.passport_issued_date &&
          address.passport_number &&
          address.passport_serial
        ),
      [address],
    );

    return (
      <AddressItemWrapper
        onClick={handleSelect}
        $isSelected={isSelected}
        onDoubleClick={handleDoubleClick}
        ref={mainRef}
        className={className}
      >
        {deletedItems.includes(addressID) ? (
          <UndoWrapper
            text={t("account.addressDeleted")}
            onClick={() => handleUndo(addressID)}
          />
        ) : (
          <>
            <AddressTitle>
              {fullName}
              {isPassportValid && <AddressVerifyIcon type={IconType.Id} />}
              {!isHideButtons && (
                <AddressButtons>
                  <AddressButton
                    color={ButtonColor.Primary}
                    hidden={!editable}
                    onClick={editAddress}
                  >
                    {t("common.edit")}
                  </AddressButton>
                  <AddressButton
                    color={ButtonColor.Red}
                    hidden={!deletable}
                    onClick={() => handleRemove(addressID)}
                  >
                    {t("common.remove")}
                  </AddressButton>
                </AddressButtons>
              )}
            </AddressTitle>
            <AddressText>{addressText}</AddressText>
          </>
        )}
      </AddressItemWrapper>
    );
  },
);

export default AddressItem;
