import { fadeInRight } from "react-animations";
import styled, { css, keyframes } from "styled-components";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";

interface ReportBugButtonProps {
  isDeclarationsPanel: boolean;
  isToastWithAxiosError: boolean;
}

export const ReportBugButton = styled(Button)<ReportBugButtonProps>`
  background-color: ${(props) => props.theme.red};
  width: 40px;
  height: 40px;
  position: fixed;
  right: 15px;
  bottom: ${({ isDeclarationsPanel }) =>
    isDeclarationsPanel ? "90px" : "20px"};
  border-radius: 50px;
  z-index: 999999;
  pointer-events: auto;
  animation: 0.8s ${keyframes`${fadeInRight}`};
  transition: bottom 0.8s ease-in-out;
  padding-left: 9px;

  ${(props) => props.theme.sm`
    width: 40px;
    height: 40px;
    padding: 10px;
    bottom: ${props.isDeclarationsPanel ? "80px" : "17px"};
    visibility: ${props.isToastWithAxiosError ? "hidden" : "visible"};
    z-index: ${props.isToastWithAxiosError ? 1 : 99999};
    transition: visibility 0.5s ease-in-out;
  `};
`;

export const ReportIcon = styled(Icon)`
  width: 22px;
  height: 22px;

  ${(props) => props.theme.sm`
    width: 20px;
    height: 20px;
  `}
`;

export const BugButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: fit-content;
  gap: 10px;
  max-height: 60px;
  align-items: center;

  ${(props) => props.theme.sm`
    gap: 5px;
  `}
`;

export const BugButtonMessage = styled.div``;

interface BugButtonProps {
  isShowBtn: boolean;
}

export const BugButton = styled.button<BugButtonProps>`
  position: relative;
  opacity: ${({ isShowBtn }) => (isShowBtn ? 1 : 0)};
  width: ${({ isShowBtn }) => (isShowBtn ? "fit-content" : "0")};
  margin: ${({ isShowBtn }) => (isShowBtn ? "5px 0" : "0")};
  overflow: hidden;
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  align-items: center;
  padding: 0px;
  color: #fff;
  text-decoration: underline;
  z-index: 9999999;
  border: none;
  pointer-events: auto;
  cursor: pointer;
  transition:
    opacity 0.5s ease,
    max-height 0.5s ease,
    margin 0.5s ease;

  &:hover {
    color: #f0f0f0;
  }

  &:active,
  &:focus {
    outline: none;
  }
`;

export const BugButtonDivider = styled.div<BugButtonProps>`
  opacity: ${({ isShowBtn }) => (isShowBtn ? 1 : 0)};
  height: ${({ isShowBtn }) => (isShowBtn ? "30px" : "0")};
  width: ${({ isShowBtn }) => (isShowBtn ? "fit-content" : "0")};
  width: 1px;
  background-color: #fff;
  transition:
    opacity 0.5s ease,
    max-height 0.5s ease,
    margin 0.5s ease;
`;
