import { AxiosResponse } from "axios";
import {
  InventoryItemResponseDto,
  ItemsPutAwayDto,
  ItemsReceivedItemDto,
} from "types/api/items";
import { StorageParamsDto } from "types/api/shipments";

import axiosInstance from "./axios_instance";

const ItemsAPI = {
  putAway: ({ id, data }: ItemsPutAwayDto) =>
    axiosInstance.patch(`/items/${id}/put_away`, data),
  updateItemComment: ({
    id,
    data,
  }: ItemsReceivedItemDto): Promise<
    AxiosResponse<{ items: { customer_comment: string; id: number }[] }>
  > => axiosInstance.patch(`/items/${id}/received_item`, data),
  getInventoryItems: async ({
    page,
    filter,
  }: StorageParamsDto): Promise<InventoryItemResponseDto[]> => {
    const res = await axiosInstance.get("/items", {
      params: { page, ...filter },
    });

    return res.data;
  },
};

export default ItemsAPI;
