import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { SearchInput } from "@/components/SearchInput";
import { Select } from "@/components/Select";
import { WeightRangeInput } from "@/components/WeightRangeInput";

import { IconType, InputIconColor } from "@/enums";
import { StorageSearchValues } from "@/types/StorageForms/Search";

import { CompactDateRangeFilter } from "../CompactSearchComponents";
import {
  CloseIcon,
  CloseIconWrapper,
  Content,
  ContentWrapper,
  FilterCounter,
  FilterFullWidthButton,
  FilterIcon,
  FilterLogo,
  FiltersContainer,
  FilterSegmentButton,
  FiltersToggleButton,
  MenuIcon,
} from "./Search.styles";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { weightStringFromRange } from "@/components/WeightRangeInput/helpers";

import { getFormattedDate } from "@/utils/formatDate";

import { useAuthStore, useStorageStore } from "@/store";
import { getDayjsLocale } from "@/translations/dayjs-locale";

const createDate = (days: number, months: number, years: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  date.setMonth(date.getMonth() + months);
  date.setFullYear(date.getFullYear() + years);
  return date;
};

interface CompactSearchProps {
  formOptions: FormikProps<StorageSearchValues>;
  handleSetResetCompactFunction: (func: () => void) => void;
}

const CompactSearch: FC<CompactSearchProps> = ({
  formOptions,
  handleSetResetCompactFunction,
}) => {
  const {
    values,
    initialValues,
    handleSubmit,
    handleChange,
    handleReset,
    setFieldValue,
  } = formOptions;
  const {
    userAuth: { display_weight_in: units },
  } = useAuthStore();
  const { shops } = useStorageStore();

  const { t, i18n } = useTranslation("common");

  const locale = getDayjsLocale(i18n.language);

  const filterTypes = {
    shop: "shop",
    date: "date",
    weight: "weight",
  };

  const weightUtils = t(`units.${units}.primaryShort`);

  const today = new Date();
  const minDate = createDate(0, 0, -2);
  const maxDate = today;

  const initialDateRange = {
    startDate: today,
    endDate: today,
    key: "selection",
  };

  const [isFilter, setIsFilter] = useState(false);
  const [openedFilter, setOpenedFilter] = useState<string | null>(null);
  const [shopValue, setShopValue] = useState<any>("");
  const [selectionRange, setSelectionRange] = useState(initialDateRange);

  const shopOptions = useMemo(
    () =>
      shops
        .map((shop) => {
          const name = shop.name;
          const image = shop.thumb_image_url;
          return { label: name, value: name, image };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    [shops],
  );

  const filterProps = useMemo(
    () => ({
      values,
      initialValues,
      handleSubmit,
      handleChange,
      setFieldValue,
    }),
    [values, initialValues, handleSubmit, handleChange, setFieldValue],
  );

  const toggleFilterMenu = useCallback(() => {
    setIsFilter(!isFilter);
  }, [isFilter, setIsFilter]);

  const handleSelectedFilter = useCallback(
    (filterName: string | null) => {
      if (openedFilter === filterName) {
        setOpenedFilter(null);
      } else {
        setOpenedFilter(filterName);
      }
    },
    [openedFilter, setOpenedFilter],
  );

  const handleShopValueChange = useCallback(
    (value: any) => {
      setShopValue(value);
      if (value) {
        handleSelectedFilter(null);
      }

      setFieldValue("vendor", value);
      handleSubmit();
    },
    [setShopValue, handleSelectedFilter, setFieldValue, handleSubmit],
  );

  const handleDateSelection = useCallback(
    (range: any) => {
      setSelectionRange(range["selection"]);
    },
    [setSelectionRange],
  );

  const hanldeApplyDateRange = useCallback(() => {
    handleSelectedFilter(null);
    setFieldValue("date", selectionRange);
    handleSubmit();
  }, [selectionRange, setFieldValue, handleSubmit, handleSelectedFilter]);

  const handleClearDateRange = () => {
    //FIXME: If we don't want to close date filter, but then it's not obvious if clear was successful
    // event.stopPropagation();
    setFieldValue("date", {});
    handleSubmit();
  };

  const resetInternalValues = useCallback(() => {
    setSelectionRange(initialDateRange);
    setShopValue("");
  }, [setSelectionRange, setShopValue]);

  const handleResetClick = useCallback(() => {
    handleSelectedFilter(null);
    setIsFilter(false);
    resetInternalValues();
    handleReset();
    handleSubmit();
  }, [handleReset, handleSubmit, handleSelectedFilter, resetInternalValues]);

  useEffect(() => {
    handleSetResetCompactFunction(() => handleResetClick);
  }, [handleResetClick, handleSetResetCompactFunction]);

  useEffect(() => {
    if (!isFilter) handleResetClick();
  }, [isFilter]);

  const searchContent = (
    <SearchInput
      name="trackingNumber"
      value={values.trackingNumber}
      initialValue={initialValues.trackingNumber}
      placeholder={t("parcels.search.filterByTransactionNumber")}
      {...filterProps}
    />
  );

  const shopFilterButton = (showTitleOnly: boolean) => {
    const shopSelected = showTitleOnly === false && !!values.vendor;
    const shopTitle = shopSelected ? null : t("parcels.search.shop");
    const image = shopSelected && shopValue.image;
    return (
      <FilterSegmentButton
        selected={!!shopSelected}
        onClick={() => handleSelectedFilter(filterTypes.shop)}
      >
        {image ? <FilterLogo image={image} size={18} /> : null}
        {shopTitle}
      </FilterSegmentButton>
    );
  };

  const activeFiltersCount = () => {
    const date = values.date;
    const isRange = typeof date === "object";
    const startDate = isRange && date.startDate;
    const endDate = isRange && date.endDate;

    const hasSelectedDateRange = !!startDate && !!endDate;
    const activeFiltersCount = [
      !!values.weightRange && !!values.weightRange.length,
      hasSelectedDateRange,
      values.vendor,
    ].filter((x) => x).length;

    return activeFiltersCount === 0 ? null : activeFiltersCount;
  };

  const dateFilterButton = () => {
    const date = values.date;
    const isRange = typeof date === "object";
    const startDate = isRange && date.startDate;
    const endDate = isRange && date.endDate;

    const hasSelectedDateRange = !!startDate && !!endDate;
    let title = t("parcels.search.date");
    if (hasSelectedDateRange) {
      const startTitle = getFormattedDate(startDate, locale, "MMM DD");
      const endTitle = getFormattedDate(endDate, locale, "MMM DD");
      title = `${startTitle} - ${endTitle}`;
    }

    return (
      <FilterSegmentButton
        selected={hasSelectedDateRange}
        onClick={() => handleSelectedFilter(filterTypes.date)}
      >
        {title}
      </FilterSegmentButton>
    );
  };

  const weightFilterButton = () => {
    const weightButtonTitle = values.weightRange
      ? `${weightStringFromRange(values.weightRange)} ${weightUtils}`
      : t("parcels.weight");
    return (
      <FilterSegmentButton
        selected={!!values.weightRange && !!values.weightRange.length}
        onClick={() => handleSelectedFilter(filterTypes.weight)}
      >
        {weightButtonTitle}
      </FilterSegmentButton>
    );
  };

  const allFiltersContent = (
    <FiltersContainer>
      {shopFilterButton(false)}
      {dateFilterButton()}
      {weightFilterButton()}
    </FiltersContainer>
  );

  const selectedShopFilterContent = (
    <FiltersContainer>
      {shopFilterButton(true)}
      <Select
        autoFocus
        isSearchable
        defaultMenuIsOpen={true}
        value={shopValue}
        onChange={handleShopValueChange}
        minHeight={34}
        options={shopOptions}
        placeholder={`${t("parcels.search.shop")}:`}
        isClearable
        onClick={(e) => e.preventDefault()}
      />
    </FiltersContainer>
  );

  const selectedWeightFilterContent = (
    <FiltersContainer>
      <WeightRangeInput
        name="weightRange"
        value={values.weightRange}
        initialValue={initialValues.weightRange}
        placeholder={`3.5 - 5.5 ${weightUtils}`}
        handleUnfocus={() => handleSelectedFilter(null)}
        {...filterProps}
      />
      <FilterSegmentButton
        onClick={() => handleSelectedFilter(filterTypes.weight)}
      >
        {t("parcels.weight")}
      </FilterSegmentButton>
    </FiltersContainer>
  );

  const selectedDateFilterContent = (
    <FiltersContainer>
      <FilterFullWidthButton
        onClick={() => handleSelectedFilter(filterTypes.date)}
      >
        {t("parcels.search.date")}
        {typeof values.date !== "string" &&
          values.date.startDate &&
          values.date.endDate && (
            <CloseIconWrapper onClick={handleClearDateRange}>
              <CloseIcon type={IconType.Close} color={InputIconColor.Red} />
            </CloseIconWrapper>
          )}
      </FilterFullWidthButton>
      <CompactDateRangeFilter
        language={i18n.language}
        minDate={minDate}
        maxDate={maxDate}
        selectionRange={selectionRange}
        handleDateSelection={handleDateSelection}
        hanldeApplyDateRange={hanldeApplyDateRange}
      />
    </FiltersContainer>
  );

  const filtersContent = () => {
    if (openedFilter === null) {
      return allFiltersContent;
    } else {
      switch (openedFilter) {
        case filterTypes.shop:
          return selectedShopFilterContent;
        case filterTypes.date:
          return selectedDateFilterContent;
        case filterTypes.weight:
          return selectedWeightFilterContent;
        default:
          return allFiltersContent;
      }
    }
  };

  return (
    <ContentWrapper>
      <Content>{isFilter ? filtersContent() : searchContent}</Content>
      <FiltersToggleButton onClick={toggleFilterMenu}>
        <FilterIcon type={IconType.Filter} />
        <MenuIcon type={IconType.Menu} />
        {activeFiltersCount() && (
          <FilterCounter>{activeFiltersCount()}</FilterCounter>
        )}
      </FiltersToggleButton>
    </ContentWrapper>
  );
};

export default CompactSearch;
