import styled from "styled-components";

interface RasterIcon {
  icon: string;
  size?: number[];
}

export const IconWrapper = styled.i<{
  iconUrl: string;
  color?: string;
  className?: string;
}>`
  display: block;
  height: 20px;
  width: 20px;
  background-color: ${({ color, theme }) => color || theme.grey};
  -webkit-mask: url(${({ iconUrl }) => iconUrl}) center/cover no-repeat;
  mask: url(${({ iconUrl }) => iconUrl}) center/cover no-repeat;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transition:
    -webkit-mask 0.3s ease,
    mask 0.3s ease,
    background-color 0.3s ease;

  @supports not (
    -webkit-mask: url(${({ iconUrl }) => iconUrl}) center/cover no-repeat
  ) {
    background-image: url(${({ iconUrl }) => iconUrl});
    background-size: cover;
  }
`;

export const RasterIcon = styled.div<RasterIcon>`
  background-image: url(${(props) => props.icon});
  background-size: cover;
  width: ${(props) => `${props.size?.[0] ?? 22}px`};
  height: ${(props) => `${props.size?.[1] ?? 22}px`};
`;

// regular svg icon is 18px without padding, so we shift (24 - 18) / 2 -> 3px to left and right
export const StyledPngImg = styled.img`
  margin-left: -3px;
  margin-right: 3px; // right margin for svg is 6px and because we are shifted by 3px already we add just 3px
`;
