import React, { FC, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Tab, TabsView } from "@/containers/RightPanel/components";
import {
  Content,
  Heading,
  ContentWrapper as Wrapper,
} from "@/containers/RightPanel/RightPanel.styles";
import CustomsDeclarationV2 from "@/components/CustomDeclaration/CustomsDeclarationV2";

import { Spinner } from "@/styles/common";
import { USD } from "@/styles/form";

import {
  calculateCustomsDataTotal,
  CustomsItem,
} from "@/utils/customsDataHelpers";
import formatter from "@/utils/formatter";
import {
  useOutgoingCancelConsolidation,
  useOutgoingCreateCustomsDeclaration,
  useOutgoingDeleteCustomsDeclaration,
  useOutgoingDetailedItem,
  useOutgoingUpdateCustomsDeclaration,
} from "@/hooks/react-query/outgoing";

import useOutgoingStore from "@/store/useOutgoingStore";
import { ConsolidationPanelData } from "@/store/useRightPanelStore";
import { useRightPanelStore, useServicesStore } from "@/store";

import { CancelPacking } from "../CancelPacking";
import { ParcelInfoTab } from "../ParcelInfoTab";
import {
  CustomsDeclarationContent,
  DeclarationTotal,
  HeaderDate,
} from "./ConsolidationPanel.styles";
import { cancelAndPayAvaiable, customDeclarationEditable } from "./helpers";

interface ConsolidationPanelProps {
  isOpen: boolean;
  panelData: ConsolidationPanelData;
}

const ConsolidationPanel: FC<ConsolidationPanelProps> = React.memo(
  ({ isOpen, panelData }) => {
    const { t } = useTranslation("common");
    const { closeRightPanel } = useRightPanelStore();
    const { isLoadingDetailedItem, isLoadingCustomsDeclaration, detailedItem } =
      useOutgoingStore();
    const { allDeliveryMethods } = useServicesStore();

    const [isCanceling, setCanceling] = useState(false);
    const id = panelData.detailedItemID;
    const { refetch: getOutgoingDetailedItem } = useOutgoingDetailedItem(id, {
      enabled: false,
    });

    const { mutateAsync: createCustomsDeclaration } =
      useOutgoingCreateCustomsDeclaration();
    const { mutateAsync: deleteCustomsDeclaration } =
      useOutgoingDeleteCustomsDeclaration();
    const { mutateAsync: updateCustomsDeclaration } =
      useOutgoingUpdateCustomsDeclaration();
    const { mutateAsync: cancelConsolidation } =
      useOutgoingCancelConsolidation();

    const detailed = detailedItem[id];

    const delivery = detailed?.preferred_carrier;

    const deliveryMethod = allDeliveryMethods.find(
      (method) => method.name === delivery,
    );
    const isShowHSCodeColumn =
      deliveryMethod && deliveryMethod.requires_hs_code;

    const heading = detailed?.sku;
    const createdAt = new Date(detailed?.created_at ?? "");
    const createdAtStr = formatter.date_ddmmyyyy(createdAt);

    const declarations = detailed?.customs_declarations ?? [];
    const shouldRender = !!detailed && !isLoadingDetailedItem;
    const state = detailed?.state ?? "";

    const editCustomsData = customDeclarationEditable(state);
    const isCancelAndPayAvailable = cancelAndPayAvaiable(state);

    const getTotal = (nameProp: string) =>
      declarations.reduce((sum: number, information) => {
        if (nameProp in declarations) {
          return (
            sum +
            ((information[nameProp as keyof typeof information] as number) || 0)
          );
        }

        return sum;
      }, 0);

    const [headerData, setHeaderData] = useState({
      size: declarations.length || 0,
      total: formatter.currency(getTotal("value")),
    });

    const handleCancelPacking = useCallback(() => {
      closeRightPanel();
      cancelConsolidation(id);
    }, [id, closeRightPanel, cancelConsolidation]);

    const toggleCanceling = () => setCanceling(!isCanceling);

    useEffect(() => {
      if (isOpen && !detailed?.estimate && id) {
        getOutgoingDetailedItem();
      }
    }, [detailed, isOpen, getOutgoingDetailedItem, id]);

    const parcelInfoPanel = () => {
      if (isCancelAndPayAvailable) {
        return isCanceling ? (
          <Content>
            <CancelPacking
              handleCancelPacking={handleCancelPacking}
              handleConfirmPacking={toggleCanceling}
            />
          </Content>
        ) : (
          <ParcelInfoTab onPackingCancel={toggleCanceling} id={id} />
        );
      } else {
        return <ParcelInfoTab id={id} />;
      }
    };

    const renderCustomsDeclaration = () => {
      return (
        <>
          <DeclarationTotal>
            {`${t("shipping.total")}: ${headerData.size} ${t(
              "shipping.totalRows",
            )} - $${headerData.total} `}
            <USD>USD</USD>
          </DeclarationTotal>
          {isOpen && (
            <CustomsDeclarationV2
              itemId={id}
              readOnly={!editCustomsData}
              declarations={declarations}
              create={createCustomsDeclaration}
              update={updateCustomsDeclaration}
              remove={deleteCustomsDeclaration}
              onCustomsDataChange={handleCustomsDataChange}
              noPadding
              isShowHSCodeColumn={isShowHSCodeColumn}
              isHSCodeValidationRequired
            />
          )}
        </>
      );
    };

    const renderCustomsDeclarationInfoPanel = () => {
      return (
        <>
          <CustomsDeclarationContent>
            {isLoadingCustomsDeclaration ? (
              <Spinner isActive />
            ) : (
              renderCustomsDeclaration()
            )}
          </CustomsDeclarationContent>
        </>
      );
    };

    const handleCustomsDataChange = (newValues: CustomsItem[]) => {
      const [newTotal, newLength] = calculateCustomsDataTotal(newValues);
      setHeaderData({
        size: newLength,
        total: newTotal,
      });
    };

    return (
      <Wrapper>
        <Spinner isActive={isLoadingDetailedItem} />
        {shouldRender && (
          <>
            <Heading>
              {heading}
              <HeaderDate>{`${t("common.from")} ${createdAtStr}`}</HeaderDate>
            </Heading>

            <TabsView openTab={panelData.openTab}>
              <Tab title={t("parcels.details")}>{parcelInfoPanel()}</Tab>
              <Tab title={t("parcels.customsData")}>
                {renderCustomsDeclarationInfoPanel()}
              </Tab>
            </TabsView>
          </>
        )}
      </Wrapper>
    );
  },
);

export default ConsolidationPanel;
