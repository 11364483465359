import React, { FC, MouseEvent, useCallback, useState } from "react";

import { useFormik } from "formik";

import { AddAddressButton } from "@/components/Addresses/components";
import { LoadingBar } from "@/components/LoadingBar";

import { useAddresses } from "@/hooks/react-query/destinations";
import { useSkeletonCalculatedItems } from "@/hooks";

import { useDestinationsStore, useRightPanelStore } from "@/store";

import {
  BooksRecipientsList,
  BooksSearchPlaceholder,
  BooksSearchRecipients,
} from "./components/BooksRecipientsList";
import { RecipientsSkeletonLoader } from "./components/BooksRecipientsList/RecipientsSkeletonLoader";
import {
  AddAddressBlock,
  RecipientsHeading,
  StyledGrid,
  Wrapper,
} from "./styles";

interface SearchAddressDto {
  search_in_address: string;
}

const BooksRecipients: FC = React.memo(() => {
  const { numItems, containerRef } = useSkeletonCalculatedItems(0);
  const { addresses: recipientsItems } = useDestinationsStore();
  const hasItems = !!recipientsItems.length;
  const { isOpenRightPanel } = useRightPanelStore();
  const [searchAddressDto, setSearchAddressDto] = useState<
    Partial<SearchAddressDto>
  >({});
  const { isLoading: isDestinationsLoading, isSuccess } =
    useAddresses(searchAddressDto);

  const { handleReset, handleSubmit, setFieldValue, initialValues, values } =
    useFormik({
      initialValues: {
        address: "",
      },
      onSubmit: (values) => {
        setSearchAddressDto(
          values.address
            ? {
                search_in_address: values.address,
              }
            : {},
        );
      },
    });

  const handleResetClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleReset(e);
      handleSubmit();
    },
    [handleReset, handleSubmit],
  );

  return (
    <Wrapper ref={containerRef}>
      <StyledGrid fluid>
        <LoadingBar isLoading={isDestinationsLoading} />
        <RecipientsHeading $isPanelOpened={isOpenRightPanel}>
          <BooksSearchRecipients
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            values={values}
            initialValues={initialValues}
          />
          <AddAddressBlock>
            <AddAddressButton isRecipientsPage />
          </AddAddressBlock>
        </RecipientsHeading>

        {isDestinationsLoading && !isSuccess && (
          <RecipientsSkeletonLoader numItems={numItems} />
        )}

        {hasItems ? (
          <BooksRecipientsList
            recipientsItems={recipientsItems}
            disabled={isDestinationsLoading}
          />
        ) : (
          !isDestinationsLoading && (
            <BooksSearchPlaceholder handleReset={handleResetClick} />
          )
        )}
      </StyledGrid>
    </Wrapper>
  );
});

export default BooksRecipients;
