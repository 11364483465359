import { create } from "zustand";

import { CartResponseDto } from "@/types/api/cart";
import { AddressDto } from "@/types/api/destinations";
import { ConsolidationItem } from "@/types/api/history";
import { OutgoingItem } from "@/types/api/outgoing";
import {
  ShipmentResponseDto,
  ShipmentsUpdateCommentDto,
} from "@/types/api/shipments";

import { CartSelectForOutgoing, CartSelectItem } from "./useCartStore";
import { HistoryFullDetailed } from "./useHistoryStore";
import { PackingMethod } from "./useServicesStore";

export enum RightPanelType {
  PAY_ALL = "PAY_ALL",
  WEEK_ITEM = "WEEK_ITEM",
  ADDRESS_FORM = "ADDRESS_FORM",
  STORAGE_ITEM = "STORAGE_ITEM",
  NOT_FINISHED = "NOT_FINISHED",
  SERVICE_REQUEST = "SERVICE_REQUEST",
  WAITING_PAYMENT = "WAITING_PAYMENT",
  PACK_OPTIONS = "PACK_OPTIONS",
  IN_PROGRESS = "IN_PROGRESS",
  DELIVERY_METHODS = "DELIVERY_METHODS",
  PROHIBITED_FOUND = "PROHIBITED_FOUND",
  CHANGING_ADDRESS = "CHANGING_ADDRESS",
  REVIEW_AND_PAY = "REVIEW_AND_PAY",
  CONFIRM_PAYMENT_DETAILS = "CONFIRM_PAYMENT_DETAILS",
  PARCEL_DETAILED = "PARCEL_DETAILED",
  PACKING_GRADE = "PACKING_GRADE",
  HELP_PANEL = "HELP_PANEL",
  EXPECTED_ITEM = "EXPECTED_ITEM",
  BOOKS_ADD_FUNDS = "BOOKS_ADD_FUNDS",
}

type RightPanelDataMap = {
  [RightPanelType.NOT_FINISHED]: NotFinishedPanelPanelData;
  [RightPanelType.ADDRESS_FORM]: AddressFormPanelData;
  [RightPanelType.CHANGING_ADDRESS]: ChangingAddressPanelData;
  [RightPanelType.HELP_PANEL]: HelpPanelData;
  [RightPanelType.WEEK_ITEM]: WeekItemPanelData;
  [RightPanelType.STORAGE_ITEM]: StorageItemPanelData;
  [RightPanelType.EXPECTED_ITEM]: ExpectedItemPanelData;
  [RightPanelType.SERVICE_REQUEST]: ServiceRequestPanelData;
  [RightPanelType.PARCEL_DETAILED]: ParcelDetailedPanelData;
  [RightPanelType.IN_PROGRESS]: ConsolidationPanelData;
  [RightPanelType.WAITING_PAYMENT]: ConsolidationPanelData;
  [RightPanelType.PACKING_GRADE]: PackingGradePanelData;
  [RightPanelType.DELIVERY_METHODS]: DeliveryMethodsPanelData;
  [RightPanelType.REVIEW_AND_PAY]: ReviewAndPayPanelData;
  [RightPanelType.CONFIRM_PAYMENT_DETAILS]: ConfirmPaymentDetailsPanelData;
  [RightPanelType.PACK_OPTIONS]: PackOptionsPanelData;
};

export type WeekItemPanelData = {
  shippingMethod: string;
  foundPackageId?: number;
  returnPreviousPanel?: RightPanelType;
  weekNumber: string;
  selectedYear: number;
  id?: number;
};

export type AddressFormPanelData = {
  isEditForm: boolean;
  addressId?: number;
  address?: AddressDto | HistoryFullDetailed;
  initialPanelData?: any;
  openTab?: string;
  isOutgoingUpdateAddress?: boolean;
  isRecipientEditForm?: boolean;
  returnPreviousPanel?: RightPanelType;
  detailedItemID?: number;
  updateAddress?: any;
};

export type NotFinishedPanelPanelData = {
  detailedItemID?: number;
  parcel:
    | CartResponseDto
    | CartSelectForOutgoing
    | Partial<CartSelectItem>
    | null;
  openedFromStorage?: boolean;
  foundPackageId?: number;
  excessItems?: any;
};

export type HelpPanelData = {
  preventReLoading: boolean;
  hideBackButton?: boolean;
  showHelpersCode?: string;
  readOnly?: boolean;
  headerTitle?: string;
};

export type PackOptionsPanelData = Partial<HelpPanelData> & {
  returnScreen: RightPanelType;
  useInventoryAddons?: boolean;
  overrideAndEnablePackOptions?: boolean;
  id: string | number;
  description?: string;
};

export type ReviewAndPayPanelData = {
  isEditCustomData?: boolean;
  detailedItemID: number;
  consolidation?: HistoryFullDetailed | null;
  returnPreviousPanel?: RightPanelType;
};

export type ChangingAddressPanelData = NotFinishedPanelPanelData & {
  returnPreviousPanel: RightPanelType;
  addressId?: number | string;
  isCartVariant: boolean;
  cart: CartResponseDto | null;
  setAccess: () => void;
};

export type DeliveryMethodsPanelData = NotFinishedPanelPanelData & {
  returnPreviousPanel: RightPanelType;
  isCartVariant: boolean;
  cart: CartResponseDto | null;
  setAccess: () => void;
  deliveryMethod?: string;
  deliveryCountry?: string;
};

export type PackingGradePanelData = Partial<NotFinishedPanelPanelData> & {
  consolidationSku?: string;
  deliveryMethod?: string;
  packingCode: string;
  packingMethods: PackingMethod[];
  selectedConsolidationAddons?: (string | number)[];
  isCartVariant: boolean;
  cart: CartResponseDto | null;
  returnPreviousPanel: RightPanelType;
  setAccess: (selectedAddonsIds: string[]) => void;
};

export type ConfirmPaymentDetailsPanelData = Partial<ReviewAndPayPanelData> & {
  detailedItemID: number;
  returnPreviousPanel: RightPanelType;
};

export type ParcelDetailedPanelData = {
  sku: string;
  id: number;
  items: ConsolidationItem[];
  number: string;
  returnPreviousPanel: RightPanelType;
};

export type ConsolidationPanelData = {
  detailedItemID: number;
  excessItems?: any[];
  openTab?: string;
  isEditCustomData?: boolean;
  returnPreviousPanel?: RightPanelType;
  foundPackageId?: number;
  shipments?: any[];
  sku?: string;
};

export type ServiceRequestPanelData = {
  detailedItemID?: string | number;
  consolidationSku?: any;
  returnPreviousPanel?: RightPanelType;
  getShipmentType?: string;
  readOnly?: boolean;
  id?: number;
  description?: string;
  item?: any[] | OutgoingItem;
  originalItem?: any;
  requests?: any[];
  emblem_thumb_url?: string;
  requestType?: string;
  preventReLoading?: boolean;
  updateComment?: (
    updateCommentDto: ShipmentsUpdateCommentDto,
  ) => Promise<ShipmentResponseDto | void>;
  requestInventoryShipmentData?: any;
  isInventoryItemVariant?: boolean;
  state?: string;
};

export type ExpectedItemPanelData = {
  selectedExpectedItem?: any;
  isEditForm?: boolean;
};

export type StorageItemPanelData = {
  readOnly?: boolean;
  id: number;
  originalItem: any;
  consolidationId?: number;
  consolidationSku?: string;
  emblem_thumb_url?: string;
  description?: string;
  returnPreviousPanel?: RightPanelType;
  rootReturnPanel?: RightPanelType;
  shippingMethod?: string;
  updateComment?: any;
  getShipmentType?: string;
  hasInventoryItems?: boolean;
  isCartVariant?: boolean;
  preventAutoCloseOnNavigationChange?: boolean;
  getShipment?: any;
  updateItemComment?: any;
  isItemInCart?: boolean;
  overrideAndEnablePackOptions?: boolean;
  detailedItemID?: number;
  excessItems?: any[];
  preventReLoading?: boolean;
  parcel?: any;
  preventImageZoom?: boolean;
  state?: string;
};

export type PanelDataByType<T extends RightPanelType> =
  T extends keyof RightPanelDataMap ? RightPanelDataMap[T] : any;

interface UseRightPanelStore {
  isOpenRightPanel: boolean;
  panelType: RightPanelType | null;
  panelData: PanelDataByType<RightPanelType> | null;
  activeTab: number;
  updateActiveTab: (tab: number) => void;
  openRightPanel: <T extends RightPanelType>(
    panelType: T | null,
    panelData?: PanelDataByType<T>,
  ) => void;
  closeRightPanel: () => void;
}

const initialState = {
  isOpenRightPanel: false,
  panelType: null,
  panelData: null,
  activeTab: 0,
};

const useRightPanelStore = create<UseRightPanelStore>((set, get) => ({
  ...initialState,
  openRightPanel: <T extends RightPanelType>(
    panelType: T | null,
    panelData?: PanelDataByType<T>,
  ) =>
    set(() => ({
      isOpenRightPanel: true,
      panelType,
      panelData,
    })),
  closeRightPanel: () => {
    const isOpenRightPanel = get().isOpenRightPanel;

    if (!isOpenRightPanel) return;

    set(() => ({
      ...initialState,
    }));
  },
  updateActiveTab: (tab) =>
    set(() => ({
      activeTab: tab,
    })),
}));

export default useRightPanelStore;
