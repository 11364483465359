import axios from "axios";
import {
  AddonsMethodsResponseDto,
  ConsolidationAddonsMethodsResponseDto,
  DeliveryShippingMethodsResponseDto,
  PackingMethodsResponseDto,
} from "types/api/cart";

import axiosInstance from "./axios_instance";

const ServicesAPI = {
  getAllDeliveryMethods: async (): Promise<
    DeliveryShippingMethodsResponseDto[]
  > => {
    const res = await axiosInstance.get("/shipping_methods");
    return res.data;
  },
  getDeliveryMethods: async (): Promise<
    DeliveryShippingMethodsResponseDto[]
  > => {
    const res = await axiosInstance.get("/current_parcel/shipping_methods");
    return res.data;
  },
  getPackingMethods: async (): Promise<PackingMethodsResponseDto[]> => {
    const res = await axiosInstance.get("/current_parcel/optional_line_items");
    return res.data;
  },
  getAddonsMethods: async (): Promise<AddonsMethodsResponseDto[]> => {
    const res = await axiosInstance.get("/addons");
    return res.data;
  },
  getConsolidationAddonsMethods: async (): Promise<
    ConsolidationAddonsMethodsResponseDto[]
  > => {
    const res = await axiosInstance.get("/consolidation_addons");
    return res.data;
  },

  postReportBugUserSnap: async (
    orderedInputs: {
      field_type: string;
      label: string;
      value: string;
    }[],
  ) => {
    const res = await axios.post(
      "https://widget.usersnap.com/api/widget/xhrrpc?submit_feedback",
      {
        method: "submit_feedback",
        type: "rpc",
        params: {
          api_key: process.env.REACT_APP_USERSNAP_API_KEY_FOR_API,
          attachments: [],
          visitor: "dev@sabo.com",
          screen_recording: false,
          reporter: {},
          geo: "none",
          labels: "none",
          ordered_inputs: orderedInputs,
        },
      },
    );

    return res;
  },
};

export default ServicesAPI;
