import React, { FC, useEffect, useState } from "react";

import { IconType } from "@/enums";

import { IconWrapper, StyledPngImg } from "./Icon.styles";

export interface IconProps {
  type?: IconType;
  color?: string;
  className?: string;
}

const getIcon = async (type: string) => {
  if (!type) return;

  try {
    const icon = await import(`./icons/${type}.svg`);
    return icon.default;
  } catch (error) {
    return "";
  }
};
const Icon: React.FC<IconProps> = ({ type, color, className = "" }) => {
  const [iconUrl, setIconUrl] = useState<string>("");

  useEffect(() => {
    const loadIcon = async () => {
      const url = await getIcon(type ?? "");
      setIconUrl(url);
    };
    loadIcon();
  }, [type]);

  return (
    <IconWrapper
      iconUrl={iconUrl}
      color={color}
      className={className + " icon"}
      data-testid="icon-data-id"
    />
  );
};

export default Icon;

interface IconPngProps {
  type: string;
  height?: string;
  width?: string;
  className?: string;
}

export const IconPng: FC<IconPngProps> = ({
  type,
  height = "24",
  width = "24",
  className = "",
}) => (
  <StyledPngImg
    src={require(`./icons/${type}.png`)}
    alt=""
    height={height}
    width={width}
    className={className}
  />
);
